import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import {
  getActivitiesGQL,
  getTotalActivitiesGQL,
} from "../../queries/activities";
import { Query } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const getActivities = async (
  params: {
    category?: string;
    limit?: number;
    offset?: number;
  },
  options: Partial<QueryOptions> = {}
): Promise<Query["activities"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getActivitiesGQL,
    variables: {
      activitiesInput: params,
    },
  });

  return res.data.activities;
};
export const getTotalNbActivities = async (
  params: {
    category?: string;
  },
  options: Partial<QueryOptions> = {}
): Promise<Query["activities"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getTotalActivitiesGQL,
    variables: {
      activitiesInput: params,
    },
  });

  return res.data.activities;
};
