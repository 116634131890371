import { CoinType, CoinWallet } from "../../gql/graphql";

export const getVirtualWallet = (wallets: CoinWallet[]) => {
  return wallets.find((wallet) => wallet.coinType.includes(CoinType.Basic));
};

export const getCoinsBalanceFormat = (wallets: CoinWallet[], lng: string) => {
  const wallet = wallets.find(
    (item: CoinWallet) => item.coinType === CoinType.Basic
  );

  return wallet?.balance?.toLocaleString(lng) || 0;
};
