import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import {
  followSuggestionsGQL,
  followersGQL,
  followingGQL,
} from "../../queries/friends";
import { Query } from "../../../gql/graphql";
import { createFriendRequestCodeGQL } from "../../mutations/friends";

const apolloClient = apollo.getInstance();

export const listFollowers = async (
  userId: string,
  options: Partial<QueryOptions> = {}
): Promise<Query["followers"]> => {
  const res = await apolloClient.query({
    ...options,
    query: followersGQL,
    variables: { followersInput: { userId } },
  });

  return res.data.followers;
};

export const listFollowing = async (
  options: Partial<QueryOptions> = {},
  params?: {
    userId?: string;
    limit?: number;
    offset?: number;
  }
): Promise<Query["following"]> => {
  const res = await apolloClient.query({
    ...options,
    query: followingGQL,
    variables: { followingInput: { ...params } },
  });

  return res.data.following;
};

export const listFollowSuggestions = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["followSuggestions"]> => {
  const res = await apolloClient.query({
    ...options,
    query: followSuggestionsGQL,
    variables: {},
  });

  return res.data.followSuggestions;
};

export const createFriendRequestCode = async () => {
  const res = await apolloClient.mutate({
    mutation: createFriendRequestCodeGQL,
  });

  return res.data.createFriendRequestCode;
};
