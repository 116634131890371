import { Redirect, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { PageRoutes } from "../constants/historyOnmo";
import { Theme } from "../models/theme";
import { Translations } from "../models/translations";
import { SignIn } from "../pages/SignIn";
import { OnmoStorage } from "../models/onmoStorage";
import i18next, { changeLanguage } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { hide } from "../slices/splashScreen";
import { SignInProvider } from "../components/pages/SignInProvider";

const SignInWithEmail = React.lazy(
  () => import("../pages/SignInWithEmail/SignInWithEmail")
);
const EmailSignUp = React.lazy(
  () => import("../pages/EmailSignUp/EmailSignUp")
);
const ResetPassword = React.lazy(
  () => import("../pages/ResetPassword/ResetPassword")
);
const AuthenWithPhone = React.lazy(
  () => import("../pages/AuthenWithPhone/AuthenWithPhone")
);
const TermAndCondition = React.lazy(
  () => import("../pages/TermAndCondition/TermAndCondition")
);
const PrivacyPolicy = React.lazy(
  () => import("../pages/PrivacyPolicy/PrivacyPolicy")
);

const DefaultRoute = () => {
  return <Redirect to={PageRoutes.Landing + window.location.search} />;
};

const RoutesNotLogged = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const { app } = theme;
  const dispatch = useAppDispatch();

  const fetchNewTranslation = async () => {
    if (!theme) return;
    try {
      const language = Translations.getDefaultLanguage();
      const themeName = Theme.getTranslationOverrideName(app?.name);
      await Theme.fetchTranslationOverrides(themeName, language || "en");
    } catch (e) {
      console.log(e);
    }
    dispatch(hide());
  };

  useEffect(() => {
    // Update default language
    const newLanguage = Translations.getDefaultLanguage();
    if (i18next.language.indexOf(newLanguage) === -1) {
      Translations.setDirection(newLanguage);
      changeLanguage(newLanguage);
    }
  }, []);

  useEffect(() => {
    fetchNewTranslation();
    OnmoStorage.removeSearchHistory();
  }, [theme]);

  return (
    <Switch>
      <Route path={PageRoutes.Guest}>{<SignIn />}</Route>
      <Route path={PageRoutes.SignInEmail}>{<SignInWithEmail />}</Route>
      <Route path={PageRoutes.SignUpEmail}>{<EmailSignUp />}</Route>
      <Route path={PageRoutes.ResetPassword}>{<ResetPassword />}</Route>
      <Route path={PageRoutes.SignInPhone}>{<AuthenWithPhone />}</Route>
      <Route path={PageRoutes.TermAndCondition}>{<TermAndCondition />}</Route>
      <Route path={PageRoutes.PrivacyPolicy}>{<PrivacyPolicy />}</Route>
      <Route path={PageRoutes.SignInProvider}>{<SignInProvider />}</Route>
      <Route path={PageRoutes.Landing}>{<SignIn />}</Route>
      <Route path="*">{<DefaultRoute />}</Route>
    </Switch>
  );
};

export default RoutesNotLogged;
