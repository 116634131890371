import {
  createSelector,
  createSlice,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import { map, filter } from "lodash-es";
import { AppDispatch, RootState } from "../store";
import { HistoryTransaction } from "../models/historyTransaction";
import { listTransactions } from "../graphql/resolvers/queries/transaction";
import { TABS } from "../constants/shopWallet";
import { HistoryTransactionFactory } from "../models/historyTransactionFactory";
import { LIMIT_LOAD_TRANSACTION } from "../constants/historyTransaction";
import { setNotificationError } from "./alert";

interface ITransactionHistorySlice {
  listTransactionHistory: null | HistoryTransaction[];
  activeCategory: null | string;
  searchQuery: string;
  offset: number;
  isLoadedAll: boolean;
  isLoading: boolean;
}

const initialState = {
  listTransactionHistory: null,
  activeCategory: null,
  searchQuery: "",
  offset: 0,
  isLoadedAll: false,
  isLoading: false,
} as ITransactionHistorySlice;

export const resetTransactionHistoryState = createAction(
  "transactionHistory/resetState"
);

export const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState: initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      const { activeCategory } = action.payload;
      state.activeCategory = activeCategory;
    },
    setSearchQuery: (state, action) => {
      const { query } = action.payload;
      state.searchQuery = query;
    },
    resetTransaction: (state) => {
      state.offset = 0;
      state.listTransactionHistory = null;
      state.isLoadedAll = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListTransactionHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchListTransactionHistory.fulfilled, (state, action) => {
        const offset = action.meta.arg.offset || 0;
        const items = action.payload;
        if (offset > 0 && state.offset >= offset + items.length) {
          return;
        }
        if (items) {
          state.listTransactionHistory =
            offset === 0
              ? items
              : [...(state.listTransactionHistory || []), ...items];
        }
        state.isLoadedAll = items.length < LIMIT_LOAD_TRANSACTION;
        state.offset = offset + items.length;
        state.isLoading = false;
      })
      .addCase(fetchListTransactionHistory.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error);
      })
      .addCase(resetTransactionHistoryState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setActiveCategory, setSearchQuery, resetTransaction } =
  transactionHistorySlice.actions;

export const fetchListTransactionHistory = createAsyncThunk(
  "transactionHistory/fetchListTransactionHistory",
  async (params: { offset: number }, { dispatch }) => {
    try {
      const { offset } = params;
      const transactions = await listTransactions(
        {
          limit: LIMIT_LOAD_TRANSACTION,
          offset,
        },
        { fetchPolicy: "network-only" }
      );
      return map(
        transactions.items,
        (transaction) => new HistoryTransaction(transaction)
      );
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
      throw e;
    }
  }
);

const listTransactionHistory = (state: RootState) =>
  state.transactionHistory.listTransactionHistory;
const activeCategory = (state: RootState) =>
  state.transactionHistory.activeCategory;
const searchQuery = (state: RootState) => state.transactionHistory.searchQuery;

export const filterTransactions = createSelector(
  [listTransactionHistory, activeCategory],
  (listTransactionHistory, activeCategory) => {
    if (!listTransactionHistory) return;
    if (!activeCategory || activeCategory === TABS[0].id) {
      return HistoryTransactionFactory.groupByDateTransactions(
        listTransactionHistory
      );
    }
    const transactionFilter = filter(listTransactionHistory, (t) => {
      return activeCategory?.split(",")?.includes(t.transactionType);
    });

    return HistoryTransactionFactory.groupByDateTransactions(transactionFilter);
  }
);

export const searchTransactions = createSelector(
  [listTransactionHistory, searchQuery],
  (listTransactionHistory, searchQuery) => {
    if (!listTransactionHistory) return;
    const transactionFilter = filter(listTransactionHistory, (t) => {
      return t?.gameTitle?.toLowerCase()?.includes(searchQuery.toLowerCase());
    });
    if (transactionFilter?.length === 0) return;

    return HistoryTransactionFactory.groupByDateTransactions(transactionFilter);
  }
);

export default transactionHistorySlice.reducer;
