import React, { FC, HTMLAttributes, useEffect, useRef, useState } from "react";
import clsx from "classnames";
import { uniqueId } from "lodash-es";
import { Theme } from "../../../models/theme";
import { TOTAL_PROGRESS, useStyles } from "./ProgressBar.styles";

type ProgressBarSize = "small" | "medium" | "large" | "extraLarge";
type ProgressBarType = "linear" | "circle";

interface IProgressBarValue {
  initial: number;
  total: number;
  target: number;
}

interface IProgressBarProps {
  color: string;
  value: IProgressBarValue;
  size?: ProgressBarSize;
  timing?: number;
  type?: ProgressBarType;
  isDisableAnimation?: boolean;
}

const DEFAULT_TIMING = 800;

export const ProgressBar: FC<
  IProgressBarProps & HTMLAttributes<HTMLDivElement>
> = ({
  color,
  value,
  size,
  timing: timingProp,
  type,
  isDisableAnimation,
  children,
  ...otherProps
}) => {
  const timing = timingProp || DEFAULT_TIMING;
  const { initial, total, target } = Theme.getCovertPercent(
    value.initial,
    value.total,
    value.target
  );
  const initialProgress = (initial / total) * 100;
  const classes = useStyles(initial, target, timing);
  const [progress, setProgress] = useState<number>(initial);
  const [gradientId] = useState(uniqueId("gradient-"));
  const refBar = useRef<HTMLDivElement>(null);
  const refCircle = useRef<SVGCircleElement>(null);

  useEffect(() => {
    const finalProgress = (target / total) * 100;
    if (refBar.current && !isDisableAnimation) {
      refBar.current.style.transition = `width ${timing / 1000}s`;
    }

    const colorCircle = Theme.getSingleColorOffset(color);
    if (typeof colorCircle !== "string") {
      const gradient = document.getElementById(gradientId);
      if (gradient?.childElementCount) {
        while (gradient.firstChild) {
          gradient.lastChild && gradient.removeChild(gradient.lastChild);
        }
      }
      colorCircle.forEach((stop) => {
        const el = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "stop"
        );
        el.setAttribute("offset", stop.offset);
        el.setAttribute("stop-color", stop.color);
        if (gradient) {
          gradient.appendChild(el);
        }
        if (refCircle.current) {
          refCircle.current.style.stroke = `url(#${gradientId})`;
        }
        if (refBar.current) {
          refBar.current.style.background = color;
        }
      });
    } else {
      if (refCircle.current) {
        refCircle.current.style.stroke = color;
      }
      if (refBar.current) {
        refBar.current.style.background = color;
      }
    }

    const timeoutId = setTimeout(() => {
      if (refBar.current && !isDisableAnimation) {
        refBar.current.style.width = `${
          finalProgress > total ? total : finalProgress
        }%`;
      }
    }, 0);
    let animationId: number;
    let start: number;
    const currentProgress = (timestamp: number): void => {
      if (!start) {
        start = timestamp;
      }
      const timeProgress = Math.ceil(timestamp - start);
      if (timeProgress > timing) {
        setProgress(target);
        cancelAnimationFrame(animationId);
      } else {
        animationId = requestAnimationFrame(currentProgress);
      }
    };
    if (!isDisableAnimation) {
      animationId = requestAnimationFrame(currentProgress);
    }

    return () => {
      cancelAnimationFrame(animationId);
      clearTimeout(timeoutId);
    };
  }, [progress, color, isDisableAnimation, value]);

  const getStyleStroke = () => {
    if (!isDisableAnimation) return undefined;

    const getInitialValue = () => {
      if (initialProgress >= 100) {
        return TOTAL_PROGRESS;
      } else if (initialProgress < 5) {
        return TOTAL_PROGRESS / 20;
      } else {
        return (TOTAL_PROGRESS / 100) * initialProgress;
      }
    };

    return {
      strokeDashoffset: TOTAL_PROGRESS - getInitialValue(),
    };
  };

  if (type === "circle") {
    return (
      <div
        {...otherProps}
        className={clsx(classes.oProgressCircle, otherProps.className, size)}
      >
        <svg viewBox="0 0 40 40" id="svg-circle">
          <linearGradient x1="0" y1="0" x2="100%" y2="100%" id={gradientId} />
          <circle
            className={clsx(classes.circleG, classes.circleBackground)}
            r="18.5"
            cy="20"
            cx="20"
          />
          <circle
            ref={refCircle}
            style={getStyleStroke()}
            className={clsx(
              classes.circleG,
              classes.circleMain,
              isDisableAnimation && "disabled"
            )}
            r="18.5"
            cy="20"
            cx="20"
            strokeDashoffset="25"
            transform="rotate(-90,20,20)"
          />
        </svg>
      </div>
    );
  }
  return (
    <div
      {...otherProps}
      className={clsx(classes.root, otherProps.className, size)}
    >
      <div className={classes.text}>{children}</div>
      <div
        ref={refBar}
        style={{
          width: `${initialProgress}%`,
        }}
        className={clsx(classes.bar, isDisableAnimation && "disabled")}
      />
    </div>
  );
};

ProgressBar.defaultProps = {
  value: {
    initial: 0,
    target: 100,
    total: 100,
  },
  timing: 800,
  size: "small",
  type: "linear",
};
