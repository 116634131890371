import Strong from "../assets/header/webp/Strong.webp";
import Weak from "../assets/header/webp/Weak.webp";
import VeryWeak from "../assets/header/webp/VeryWeak.webp";
import VeryStrong from "../assets/header/webp/VeryStrong.webp";

export enum GamesTab {
  AllCategories = "All Categories",
  Trending = "Trending",
}

export const NETWORK: { [key: string]: string } = {
  "1": VeryWeak,
  "2": Weak,
  "3": Strong,
  "4": VeryStrong,
};

export const GAME_DEFAULT_RATING: { [key: string]: string } = {
  "13431": "A",
  "154159": "A",
  "153848": "A",
  "141753": "A",
  "154169": "A",
  "152549": "A",
  "13454": "A",
  "13455": "A",
  "13392": "A",
  "153977": "A",
  "147088": "A",
  "153992": "A",
  "153930": "A",
  "154183": "A",
  "153904": "A",
  "13498": "A",
  "154004": "A",
  "13515": "A",
  "13385": "A",
  "153899": "A",
  "154163": "A",
  "153916": "A",
  "154115": "A",
  "154057": "A",
  "17": "A",
  "6": "A",
  "19": "A",
  "1": "A",
  "106": "A",
  "100": "A",
  "15": "A",
  "102": "A",
  "103": "A",
  "21": "A",
  "104": "A",
  "16": "A",
  "18": "A",
  "2": "A",
  "8": "A",
  "7": "A",
  "12": "A",
  "11": "A",
  "13": "A",
  "13497": "18",
  "13426": "PG",
  "13440": "PG",
  "153876": "PG",
  "13389": "10-12-PG",
  "153822": "10-12-PG",
  "13534": "10-12-PG",
  "13395": "10-12-PG",
  "152144": "10-12-PG",
  "13546": "10-12-PG",
  "153832": "10-12-PG",
  "153837": "13",
  "153879": "13",
  "13393": "13",
  "153906": "13",
  "13436": "13",
  "101": "13",
  "14": "18-V",
  "13518": "PG",
  "154429": "PG",
  "153945": "PG",
  "13553": "7-9-PG",
  "13428": "7-9-PG",
  "13419": "7-9-PG",
  "13547": "18-V",
  "13540": "18-V",
  "13539": "18-V",
  "105": "18-V",
  "108": "18-V",
};

export const GAME_RUBYCOM_RATING: { [key: string]: string } = {
  "13431": "3",
  "154159": "3",
  "153848": "3",
  "141753": "3",
  "154169": "3",
  "152549": "3",
  "13454": "3",
  "13455": "7",
  "13392": "7",
  "153977": "3",
  "147088": "3",
  "153992": "3",
  "153930": "3",
  "154183": "3",
  "153904": "7",
  "13498": "3",
  "154004": "3",
  "13515": "7",
  "13385": "7",
  "153899": "3",
  "154163": "3",
  "153916": "3",
  "154115": "3",
  "154057": "3",
  "17": "3",
  "6": "7",
  "4": "3",
  "19": "7",
  "1": "3",
  "106": "3",
  "100": "7",
  "15": "7",
  "102": "7",
  "103": "7",
  "21": "7",
  "104": "7",
  "16": "3",
  "18": "3",
  "2": "7",
  "8": "3",
  "7": "12",
  "12": "7",
  "11": "7",
  "13": "12",
  "13426": "7",
  "13440": "12",
  "153876": "7",
  "13518": "7",
  "154429": "7",
  "153945": "7",
  "13553": "12",
  "13428": "3",
  "13419": "3",
  "13389": "12",
  "153822": "7",
  "13534": "7",
  "13395": "12",
  "152144": "3",
  "13546": "12",
  "153832": "12",
  "153837": "12",
  "153879": "12",
  "13393": "12",
  "153906": "12",
  "13436": "7",
  "101": "12",
  "13497": "16",
  "14": "16",
  "13547": "16",
  "13540": "7",
  "13539": "7",
  "105": "12",
  "108": "12",
};

/*
  Network value is from 1 to 5 but we have 4 icons so we multiply
  by this ratio to round to 4 values
*/
export const NETWORK_ROUNDED_RATIO = 4 / 5;
export const VERY_STRONG_NETWORK = 4; // html games
export const MAXIMUM_BUILD_SIZE = 25000000;

export const MAXIMUM_TIME_LOADING_STREAM = 14;

export enum TopGameRanking {
  Rookie = "Rookie",
  Pro = "Pro",
  Elite = "Elite",
  Diamond = "Diamond",
  Master = "Master",
  None = "None",
  OneThirds = "OneThirds",
  TwoThirds = "TwoThirds",
}

export enum TopGameRankingLabel {
  Rookie = "Rookie",
  Pro = "Pro",
  Elite = "Elite",
  Diamond = "Diamond",
  Master = "Master",
  None = "Try Now",
  OneThirds = "1/3",
  TwoThirds = "2/3",
}

export enum EmbedEvent {
  RoundEnd = "roundEnd",
  LiveScore = "liveScore",
  LoaderEnd = "loaderEnd",
  IsGamePaused = "isGamePaused",
}

export enum MomentTypeGA4 {
  Solo = "solo",
  Tournament = "tournament",
  BeatIt = "beat_it",
}

export enum ChallengeResultType {
  Won = "challenge_won",
  Lost = "challenge_lost",
}

export enum GameRating {
  Default = "default",
  Rubycom = "rubycom",
}

export const TopGames = [
  "101",
  "17",
  "152144",
  "13431",
  "13515",
  "108",
  "13440",
  "105",
  "103",
  "100",
  "16",
  "153876",
  "13454",
  "13518",
];

export const NETWORK_STATUS = {
  High: [5, 4],
  Medium: [3, 2],
  Low: [1, 0],
};

export const DEFAULT_TIPS = [
  "common_Do you know that you can play challenges with your friends",
  "common_Invite your friends so you can challenge them on your favorite games",
  "common_Compete with other players to see who can become the Top Gem Collector",
];

export const GAME_RESOLUTION = [432, 720, 1080];
