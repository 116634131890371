import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { getUserNotification } from "../../queries/notifications";
import { Query } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const getUserNotifications = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["userNotifications"]> => {
  try {
    const res = await apolloClient.query({
      ...options,
      query: getUserNotification,
      variables: {},
    });

    return res.data.userNotifications;
  } catch (error) {
    console.log(error);
    return [];
  }
};
