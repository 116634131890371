import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";
import { BorderRadius } from "../../../constants/borderRadius";
import {
  Colors,
  DarkThemeColors,
  LegacyColor,
} from "../../../constants/colors";
import { GridPaddingMapping } from "../../../constants/padding";
import { Theme } from "../../../models/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    position: "relative",
    transition: "background 400ms",
    cursor: "pointer",
    padding: "12px 20px",
    overflow: "hidden",
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.secondary.main,
    color: DarkThemeColors.Text1,
    borderRadius: BorderRadius.sm,
    textAlign: "-webkit-center",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "26px",
    fontFamily: "AlumniSans",
    boxSizing: "border-box",
    "& > span": {
      "&.ripple": {
        position: "absolute",
        borderRadius: "50%",
        transform: "scale(0)",
        animation: "$ripple 600ms linear",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      },
    },
    "&.with-icon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: GridPaddingMapping.xs,
    },
    "&.only-icon": {
      padding: GridPaddingMapping.xs,
    },
    "&.primary": {
      border: `1px solid ${theme.palette.secondary.main}`,
      background: theme.palette.secondary.main,
      color: Theme.pickTextColorFromBG(
        theme.palette.secondary.main,
        DarkThemeColors.Text1,
        LegacyColor.color14,
        theme.overrideFontColor
      ),
      "&.fullWidth": {
        width: "100%",
      },
      "&.small": {
        fontFamily: "Roboto",
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "18px",
      },
      "&.disabled": {
        color: DarkThemeColors.Text4,
        background: DarkThemeColors.BackgroundDisabled,
        border: "none",
        pointerEvents: "none",
      },
      "&.outlined": {
        background: "transparent",
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "transparent",
          border: `1px solid ${DarkThemeColors.Border3}`,
        },
      },
      "&.borderless": {
        color: theme.palette.secondary.main,
        background: "transparent",
        border: `1px solid transparent`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "transparent",
          border: `1px solid transparent`,
        },
      },
      "&.white-outlined": {
        background: "rgba(255,255,255,0.2)",
        border: `1px solid ${DarkThemeColors.Border3}`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "rgba(255,255,255,0.1)",
          border: `1px solid ${DarkThemeColors.Border3}`,
        },
      },
      "&.white-borderless": {
        background: "transparent",
        border: `1px solid transparent`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "transparent",
          border: `1px solid transparent`,
        },
      },
    },
    "&.secondary": {
      border: "none",
      background: "transparent",
      "&.fullWidth": {
        width: "100%",
      },
      "&.small": {
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "Roboto",
      },
      "&.outlined": {
        background: "transparent",
        border: `1px solid ${DarkThemeColors.Border3}`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "rgba(255,255,255,0.1)",
          border: `none`,
        },
      },
      "&.borderless": {
        background: "transparent",
        border: `1px solid transparent`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "transparent",
          border: `1px solid transparent`,
        },
      },
      "&.white-outlined": {
        background: "transparent",
        border: `1px solid ${DarkThemeColors.Border3}`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "rgba(255,255,255,0.1)",
          border: `1px solid #5B5B5B`,
        },
      },
      "&.white-borderless": {
        background: "transparent",
        border: `1px solid transparent`,
        "&.disabled": {
          color: DarkThemeColors.Text4,
          background: "transparent",
          border: `1px solid transparent`,
        },
      },
    },
    "&.error": {
      border: `1px solid ${Colors.TextLightError}`,
      background: Colors.TextLightError,
      color: Colors.TextDarkDefault,
      "&.fullWidth": {
        width: "100%",
      },

      "&.small": {
        fontFamily: "Roboto",
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "18px",
      },
      "&.outlined": {
        background: "transparent",
        color: Colors.TextLightError,
        "&.disabled": {
          color: Colors.BackgroundLightDisabled,
          background: "transparent",
          border: `1px solid ${Colors.BackgroundLightDisabled}`,
        },
      },
      "&.borderless": {
        color: Colors.TextLightError,
        background: "transparent",
        border: `1px solid transparent`,
        "&.disabled": {
          color: Colors.BackgroundLightDisabled,
          background: "transparent",
          border: `1px solid transparent`,
        },
      },
    },
  },
  overlay: {
    background: DarkThemeColors.BackgroundOverlay,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  "@keyframes ripple": {
    to: {
      transform: "scale(3)",
      opacity: 0,
    },
  },
  iconSize: {
    zIndex: 1,
    "&.small": {
      fontSize: 18,
      lineHeight: "18px",
    },
    "&.transformIcon": {
      transform: "scaleX(-1)",
    },
  },
}));
