export enum TournamentType {
  Basic = "BASIC",
  Pro = "PRO",
  Rookie = "ROOKIE",
  ProPercent = "PRO_PERCENT",
  RookiePercent = "ROOKIE_PERCENT",
  PracticeHtml = "PRACTICE_HTML",
  PracticeStream = "PRACTICE_STREAM",
}

export enum TournamentCategories {
  BeatIt = "BEATIT",
  Discover = "DISCOVER",
  Bonus = "BONUS",
  Practice = "PRACTICE",
  Featured = "FEATURED",
}

export enum TournamentStatus {
  Open = "OPEN",
  Closed = "CLOSED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
}

export const MAX_AVATAR_GROUP = 4;

export enum ResultTab {
  Leaderboard = "leaderboard",
  Score = "score",
}

export enum TournamentGemReward {
  Top1 = 10,
  Top2 = 6,
  Top3 = 4,
}

export enum FeaturedType {
  Tournament = "tournament",
  Game = "game",
  Contest = "contest",
  Banner = "banner",
  ShopBanner = "shop-banner",
}
