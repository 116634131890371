import { Inventory, Inventory_Category } from "../../gql/graphql";

export const getInventoryImage = (inventory?: Inventory) => {
  if (inventory?.data?.__typename === "InventoryBundleData") {
    return inventory?.data.previewUrl;
  } else return inventory?.data.url;
};

export const getCategoriesOwned = (userInventories: Inventory[]) => {
  return userInventories.filter((i) => !i.isBundle) as Inventory[];
};

export const getTitleByCategories = (category: Inventory_Category) => {
  if (category === Inventory_Category.Avatar) {
    return "shop_Avatars Owned";
  } else "Title Owned";
};

export const getInventoryTitle = (language: string, inventory: Inventory) => {
  const regionName = new Intl.DisplayNames("en", {
    type: "language",
  });
  return (
    inventory.metadata?.translations?.[`${regionName.of(language)}_title`] ||
    inventory.title
  );
};

export const getCategoryTitleKey = (category: string) => {
  const lowerString = category.toLowerCase() + "s";
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
};
