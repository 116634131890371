import { gql } from "graphql-tag";

export const listGamesGQL = gql`
  query ListGames($status: [GameStatus]!) {
    listGames(status: $status) {
      id
      title
      description
      category
      data
      timesPlayed
      rotationMode
      status
      featured
      type
      hasBattle
      hasSolo
      hasTournament
    }
  }
`;

export const getGameGQL = gql`
  query GetGame(
    $getGameInput: GetGameInput
    $getLeaderboardInput: GetLeaderboardsInput
  ) {
    getGame(getGameInput: $getGameInput) {
      id
      title
      description
      category
      timesPlayed
      featured
      data
      rotationMode
      hasTournament
      hasSolo
      hasBattle
      gamesPlayedByUser
      status
      type
      tournamentInfo {
        totalPrize
        playedAt
        avatar
        username
        userId
        topScore
        maxPlayers
        playerCount
        rankingOrder
        momentType
        momentId
        gameId
        status
        expiredAt
        startedAt
        tournamentCategories
        id
        tournamentType
        leaderboards(getLeaderboardInput: $getLeaderboardInput) {
          id
          score
          status
          tournamentId
          user {
            avatar
            id
            username
          }
          userId
        }
      }
      houseBattles {
        matchId
        matchState
        scoreToBeat
        host {
          id
          username
          avatar
          xp
        }
        prize
        winType
        createdAt
      }
      soloChallenges {
        id
        appId
        snapshotId
        title
        description
        type
        time
        data
        timesPlayed
        showTimer
        momentType
        rankOrder
        order
        status
        updatedAt
        createdAt
        isCompleted
        zoneId
      }
    }
  }
`;

export const getHouseBattleByGameIdGQL = gql`
  query GetHouseBattleByGameId($getGameInput: GetGameInput) {
    getGame(getGameInput: $getGameInput) {
      houseBattles {
        matchId
        matchState
        scoreToBeat
        oddMultiplier
        host {
          id
          username
          avatar
          xp
        }
        prize
        winType
        createdAt
      }
    }
  }
`;

export const listHotBattleGQL = gql`
  query ListHotBattles {
    listHotBattles {
      matchId
      matchState
      scoreToBeat
      winType
      createdAt
      host {
        id
        username
        avatar
      }
      game {
        id
        title
      }
    }
  }
`;

export const getUserGameSkillsGQL = gql`
  query ListUserGameSkills($listUserGameSkillsInput: ListUserGameSkillsInput) {
    listUserGameSkills(listUserGameSkillsInput: $listUserGameSkillsInput) {
      userId
      gameId
      userEloRating
    }
  }
`;

export const getUserMostPlayedGamesGQL = gql`
  query GetUserMostPlayedGames(
    $getUserMostPlayedGamesInput: GetUserMostPlayedGamesInput
  ) {
    getUserMostPlayedGames(
      getUserMostPlayedGamesInput: $getUserMostPlayedGamesInput
    ) {
      id
      title
      hasBattle
      type
      status
      category
      description
      skill
      gamesPlayedByUser
    }
  }
`;
