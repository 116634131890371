import { gql } from "graphql-tag";

export const getTournamentGQL = gql`
  query GetTournament(
    $getTournamentGetTournamentInput: GetTournamentInput
    $leaderboardsGetLeaderboardInput: GetLeaderboardsInput
  ) {
    getTournament(getTournamentInput: $getTournamentGetTournamentInput) {
      id
      gameId
      leaderboards(getLeaderboardInput: $leaderboardsGetLeaderboardInput) {
        status
        endedAt
        startedAt
        score
        user {
          xp
          banner
          avatar
          username
          id
        }
        userId
        tournamentId
        id
      }
      calculatedPrizePool
      topScore
      totalPrize
      playerCount
      myRank
      rankingOrder
      costPerUser
      status
      expiredAt
      startedAt
      momentType
      momentId
      maxPlayers
      tournamentType
      tournamentCategories
      moment {
        id
        appId
        app {
          title
          type
          category
          rotationMode
        }
        description
        snapshotId
        title
        timesPlayed
        momentType
        isCompleted
        zoneId
        time
        type
        showTimer
        rankOrder
      }
      avatar
      username
      userId
      livestreams {
        currentPlayer {
          id
          avatar
          username
        }
        id
        numberOfViewers
        status
      }
      userBestScore
    }
  }
`;

export const listTournamentsGQL = gql`
  query ListTournaments(
    $listTournamentInput: ListTournamentInput
    $leaderboardsGetLeaderboardInput: GetLeaderboardsInput
  ) {
    listTournament(listTournamentInput: $listTournamentInput) {
      id
      gameId
      momentId
      momentType
      expiredAt
      startedAt
      status
      rankingOrder
      playerCount
      highestScore
      totalPlayers
      imageUrl
      title
      calculatedPrizePool
      leaderboards(getLeaderboardInput: $leaderboardsGetLeaderboardInput) {
        status
        endedAt
        startedAt
        score
        user {
          xp
          banner
          avatar
          username
          id
        }
        userId
        tournamentId
        id
      }
      moment {
        id
        appId
        snapshotId
        title
        description
        type
        time
        unlockCost
        showTimer
        momentType
        rankOrder
        status
        app {
          id
          title
          category
          type
        }
        isCompleted
        zoneId
        createdAt
        updatedAt
      }
      totalPrize
      tournamentType
      tournamentCategories
      maxPlayers
    }
  }
`;
