import React, { FC, HTMLAttributes, useEffect } from "react";
import { useStyles } from "./ErrorScreen.style";
import { Box, Button, Typography } from "../../core";
import clsx from "classnames";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { hide } from "../../../slices/splashScreen";
import { PopupFactory } from "../../../models/popupFactory";
import { OnmoStorage } from "../../../models/onmoStorage";
import { removePopupQueue } from "../../../slices/popup";
import { PopupType } from "../../../constants/popup";
import {
  TypographyColor,
  TypographyVariant,
} from "../../core/Typography/Typography";

interface IErrorScreenProps {
  type: PopupType;
}

export const ErrorScreen: FC<
  IErrorScreenProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { type } = props;
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.theme);
  const { app } = theme;
  const maintenanceDetails = useAppSelector((state) => state.maintenance);

  const session = PopupFactory.getContentErrorScreen(
    type,
    app?.brandName,
    maintenanceDetails.reason
  );

  const reloadPage = () => {
    if (
      type === PopupType.UnsupportedDevice ||
      type === PopupType.UnsupportedBrowser
    ) {
      OnmoStorage.setShowedUnsupportedDevice();
      dispatch(removePopupQueue({ type: PopupType.UnsupportedDevice }));
      dispatch(removePopupQueue({ type: PopupType.UnsupportedBrowser }));
    }
    window.location.href = "/";
  };

  useEffect(() => {
    dispatch(hide());
  }, []);

  if (!type) return null;

  return (
    <Box {...props} className={clsx(classes.root, props.className)}>
      <Typography
        variant={TypographyVariant.HeadingH5Bold}
        color={TypographyColor.DarkDefault}
      >
        {session?.title}
      </Typography>
      <Typography
        variant={TypographyVariant.BodyB5Regular}
        color={TypographyColor.DarkSecondary}
        className={classes.description}
      >
        {session?.description}
      </Typography>
      <Button
        variant="contained"
        data-testid="button-error"
        className={classes.button}
        onClick={reloadPage}
      >
        <Typography
          variant={TypographyVariant.BodyB5Bold}
          color={TypographyColor.DarkDefault}
        >
          {session?.textButton}
        </Typography>
      </Button>
    </Box>
  );
};
