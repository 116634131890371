import { TransactionType } from "../constants/historyTransaction";
import { Currency } from "../constants/shopWallet";
import { Time } from "./time";
import {
  FullTransaction,
  TransactionActionType,
  TransactionCategory,
} from "../gql/graphql";

export class HistoryTransaction {
  type = "";
  amount = 0;
  runningBalance = 0;
  currency = "";
  reference = "";
  walletType = "";
  category = "";
  transactionType = TransactionActionType.Other;
  workflow = "";
  gameId = "";
  gameTitle = "";
  status = "";
  entryId = "";
  createdAt = new Date().toISOString();
  expiryDate = new Date().toISOString();

  constructor(historyTransaction: FullTransaction) {
    if (historyTransaction) {
      this.type = historyTransaction.type;
      this.amount = historyTransaction.amount;
      this.runningBalance = historyTransaction.runningBalance;
      this.currency = historyTransaction.currency;
      this.reference = historyTransaction.reference;
      this.walletType = historyTransaction.walletType;
      this.category = historyTransaction.category;
      this.transactionType = historyTransaction.transactionType;
      this.workflow = historyTransaction.workflow;
      this.gameId = historyTransaction.gameId || "";
      this.gameTitle = historyTransaction.gameTitle || "";
      this.status = historyTransaction.status;
      this.createdAt = historyTransaction.createdAt;
      this.expiryDate = historyTransaction.expiryDate;
      this.entryId = historyTransaction.entryId;
    }
  }

  public getTransactionText = () => {
    if ([TransactionActionType.CoinsPurchased].includes(this.transactionType)) {
      return "common_Top-up Coins";
    } else if (this.gameId) {
      return this.gameTitle;
    } else if (
      this.transactionType === TransactionActionType.SubscriptionCoinsReceived
    ) {
      return "common_Subscription";
    } else if (
      [TransactionActionType.TopupCoinsReceived].includes(this.transactionType)
    ) {
      return "common_Top-up Coins";
    } else {
      if (
        this.reference?.includes("bonus") ||
        [TransactionActionType.BonusCoinsReceived].includes(
          this.transactionType
        ) ||
        this.transactionType === TransactionActionType.CoinsExpired
      ) {
        if (this.currency === Currency.Xxx) return "common_Bonus Coins";
      }
      return "...";
    }
  };

  public isPlusCoinCash = () => {
    return this.type === TransactionType.Credit;
  };

  public getTransactionStatus = (t: Function) => {
    const currentDate = new Date().toISOString();
    if (
      [
        TransactionActionType.BonusCoinsReceived,
        TransactionActionType.SubscriptionCoinsReceived,
        TransactionActionType.TopupCoinsReceived,
      ].includes(this.transactionType)
    ) {
      return this.expiryDate > currentDate
        ? t(
            `common_Expire in ${Time.countDayLeft(
              this.expiryDate,
              this.createdAt
            )?.unit}`,
            {
              count: Time.countDayLeft(this.expiryDate, this.createdAt)?.value,
            }
          )
        : t("battle_Expired");
    } else if (
      [TransactionActionType.CoinsPaid].includes(this.transactionType)
    ) {
      switch (this.category) {
        case TransactionCategory.Battle:
          return t("history_Battle Fees paid");
        case TransactionCategory.Tournament:
          return t("history_Tournament Fees paid");
        default:
          return t("history_Solo challenge Fees paid");
      }
    } else if (
      [TransactionActionType.CoinsWon].includes(this.transactionType)
    ) {
      if (this.category === TransactionCategory.Battle) {
        return t("history_Battle Prize won");
      }
      return t("history_Tournament Prize won");
    } else if (
      [TransactionActionType.CoinsRefunded].includes(this.transactionType)
    ) {
      switch (this.category) {
        case TransactionCategory.Battle:
          return t("history_Battle fee Refunded");
        case TransactionCategory.Tournament:
          return t("history_Tournament fee Refunded");
        default:
          return t("historyTransaction_Fee refunded");
      }
      return "history_Tournament fee Refunded";
    } else {
      return t("transactionDetails_Completed");
    }
  };
}
