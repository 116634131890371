import apollo from "../../../clients/apollo";
import {
  getListInventoriesGQL,
  getUserInventoriesGQL,
} from "../../queries/inventories";
import { QueryOptions } from "@apollo/client";
import { Query, PaginationInput } from "../../../gql/graphql";
import { IParamsInventories } from "../../../types/inventories";

const apolloClient = apollo.getInstance();

export const fetchListInventories = async (
  params: IParamsInventories,
  options: Partial<QueryOptions> = {}
): Promise<Query["inventories"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getListInventoriesGQL,
    variables: { inventoriesInput: params },
  });

  return res.data.inventories;
};

export const fetchUserInventories = async (
  options: Partial<QueryOptions> = {},
  params?: PaginationInput
): Promise<Query["userInventories"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserInventoriesGQL,
    variables: { userInventoriesInput: params },
  });

  return res.data.userInventories;
};
