import { compact, uniq, pickBy } from "lodash-es";
import { AnalyticsEvent } from "../../constants/analytics";
import { ONMO_CONFIG } from "../../constants/onmo";
import { OnmoStorage } from "../onmoStorage";
import { HistoryOnmo } from "../historyOnmo";
import { GameSessionV2, GameType } from "../../gql/graphql";
import { getMomentType } from "../gamesession/gamesession";

interface CustomWindow extends Window {
  dataLayer?: Array<Record<string, unknown>>;
}

interface IAnalyticsInput {
  user_uid?: string;
  app_version?: string;
  status?: string;
  value?: string | number;
  method?: string;
  moment_type?: string;
  game_type?: string;
  game_title?: string;
  outcome?: string | number;
  virtual_currency_name?: "Gems" | "Coins";
  event: AnalyticsEvent;
  parameter?: string;
  user_idpId?: string;
  user_idpName?: string;
  gem_count?: number;
}

const gtmIds = compact(
  uniq([
    ONMO_CONFIG?.analytics?.gtmId,
    ...(ONMO_CONFIG?.analytics?.gtmIds || []),
  ])
);

const trackEvent = (e: IAnalyticsInput, gtmIds: string[] = []): void => {
  try {
    const dataLayer = {
      event: e?.event,
      method: e?.method,
      moment_type: e?.moment_type,
      game_type: e?.game_type,
      game_title: e?.game_title,
      value: e?.value,
      parameter: e?.parameter,
      user_uid: e?.user_uid || OnmoStorage.getUserId() || "visitor",
      user_idpId: e?.user_idpId || OnmoStorage.getUserIdpId() || "visitor",
      user_idpName:
        e?.user_idpName || OnmoStorage.getUserIdpName() || "visitor",
      app_version: import.meta.env.REACT_APP_BUILD,
      outcome: e?.outcome,
      status: e?.status,
      gem_count: e?.gem_count,
      virtual_currency_name: e?.virtual_currency_name,
    };

    const cleanedDataLayer = pickBy(dataLayer, (v) => v !== undefined);

    //do not delete this log
    console.log(`[analytics-[${gtmIds}]] dataLayer: `, cleanedDataLayer);

    // Google Analytics
    gtmIds.forEach((gtmId) => {
      const dataLayerCopy = { ...cleanedDataLayer, gtmId };
      (window as CustomWindow).dataLayer?.push(dataLayerCopy);
    });
  } catch (e) {
    console.warn(e);
  }
};

const signUp = (loginMethod: string) => {
  trackEvent(
    {
      event: AnalyticsEvent.SignUp,
      method: loginMethod,
    },
    gtmIds
  );
};

const login = (userId: string, userIdpId: string, userIdpName: string) => {
  trackEvent(
    {
      event: AnalyticsEvent.Login,
      method: OnmoStorage.getLoginType(),
      status: OnmoStorage.getUserRank(),
      user_uid: userId,
      user_idpId: userIdpId,
      user_idpName: userIdpName,
    },
    gtmIds
  );
};

const onboardingStep = (
  eventStep: AnalyticsEvent,
  method?: string,
  gameTitle?: string
) => {
  trackEvent(
    {
      event: eventStep,
      value: OnmoStorage.getGameOnboardingFlow(),
      game_title: gameTitle,
      method: method,
    },
    gtmIds
  );
};

const momentEnd = (gameSession: GameSessionV2, outcome: string) => {
  trackEvent(
    {
      event: AnalyticsEvent.MomentEnd,
      game_type: gameSession.moment.app.type,
      moment_type: getMomentType(gameSession.sessionType),
      game_title: gameSession.moment.app.title,
      outcome: outcome,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const momentStart = (gameSession: GameSessionV2) => {
  trackEvent(
    {
      event: AnalyticsEvent.MomentStart,
      game_type: gameSession.moment.app.type,
      moment_type: getMomentType(gameSession.sessionType),
      game_title: gameSession.moment.app.title,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const momentLoad = (params: {
  game_type?: string;
  moment_type?: string;
  game_title?: string;
}) => {
  trackEvent(
    {
      ...params,
      event: AnalyticsEvent.MomentLoad,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const momentQuit = (params: {
  game_type?: string;
  game_title?: string;
  moment_type?: string;
}) => {
  trackEvent(
    {
      ...params,
      event: AnalyticsEvent.MomentQuit,
      status: OnmoStorage.getUserRank(),
      outcome: "quit",
    },
    gtmIds
  );
};

const notificationRequest = () => {
  trackEvent({
    event: AnalyticsEvent.NotificationRequest,
    status: OnmoStorage.getUserRank(),
  });
};

const notificationResponse = () => {
  trackEvent(
    {
      event: AnalyticsEvent.NotificationResponse,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const insufficientFunds = (title: string, momentType: string) => {
  trackEvent(
    {
      event: AnalyticsEvent.InsufficientFunds,
      game_title: title,
      moment_type: momentType,
    },
    gtmIds
  );
};

const share = () => {
  trackEvent(
    {
      event: AnalyticsEvent.Share,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const earnVirtualCurrency = (
  value?: number,
  currencyName?: "Coins" | "Gems",
  method?: "game" | "daily" | "free"
) => {
  trackEvent(
    {
      event: AnalyticsEvent.EarnVirtualCurrency,
      value: value,
      virtual_currency_name: currencyName,
      status: OnmoStorage.getUserRank(),
      method: method,
    },
    gtmIds
  );
};

const spendVirtualCurrency = (
  value: number,
  currencyName: "Coins" | "Gems",
  method?: "game" | "shop"
) => {
  trackEvent(
    {
      event: AnalyticsEvent.SpendVirtualCurrency,
      value: value,
      virtual_currency_name: currencyName,
      status: OnmoStorage.getUserRank(),
      method: method,
    },
    gtmIds
  );
};

const search = () => {
  trackEvent(
    {
      event: AnalyticsEvent.Search,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const sendWebVitalsToAnalytics = (
  event: string,
  parameter: string,
  value: number
) => {
  trackEvent(
    {
      event: event as AnalyticsEvent,
      parameter: parameter,
      value: value,
    },
    gtmIds
  );
};

const tournamentResult = (rank?: number) => {
  trackEvent(
    {
      event: AnalyticsEvent.TournamentResult,
      outcome: rank,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const viewGame = (params: {
  game_title: string;
  game_type?: GameType;
  moment_type?: "Challenge" | "Battle" | "Tournament";
}) => {
  trackEvent(
    {
      ...params,
      event: AnalyticsEvent.ViewGame,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const watchTutorial = (params: {
  game_title: string;
  game_type?: GameType;
}) => {
  trackEvent(
    {
      ...params,
      event: AnalyticsEvent.WatchTutorial,
      status: OnmoStorage.getUserRank(),
      moment_type: HistoryOnmo.getGameSessionType(),
    },
    gtmIds
  );
};

const installApp = () => {
  trackEvent(
    {
      event: AnalyticsEvent.InstallApp,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const logout = () => {
  trackEvent(
    {
      event: AnalyticsEvent.Logout,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const deleteAccount = () => {
  trackEvent(
    {
      event: AnalyticsEvent.DeleteAccount,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const goLiveTapped = () => {
  trackEvent(
    {
      event: AnalyticsEvent.GoLiveTapped,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const liveChallengeWatch = (params: {
  game_title: string;
  game_type?: GameType;
  moment_type?: "Challenge" | "Battle" | "Tournament";
}) => {
  trackEvent(
    {
      ...params,
      event: AnalyticsEvent.LiveChallengeWatch,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const statusLevelUp = (params: { gem_count: number }) => {
  trackEvent(
    {
      ...params,
      event: AnalyticsEvent.StatusLevelUp,
      status: OnmoStorage.getUserRank(),
    },
    gtmIds
  );
};

const authClick = (loginMethod: string) => {
  trackEvent(
    {
      event: AnalyticsEvent.AuthClick,
      method: loginMethod,
    },
    gtmIds
  );
};

export const AnalyticsGA4 = {
  trackEvent,
  signUp,
  login,
  onboardingStep,
  momentEnd,
  momentStart,
  momentLoad,
  momentQuit,
  notificationRequest,
  notificationResponse,
  insufficientFunds,
  share,
  earnVirtualCurrency,
  spendVirtualCurrency,
  search,
  sendWebVitalsToAnalytics,
  tournamentResult,
  viewGame,
  watchTutorial,
  installApp,
  logout,
  deleteAccount,
  goLiveTapped,
  liveChallengeWatch,
  statusLevelUp,
  authClick,
};
