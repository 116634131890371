import { compact, filter, groupBy, map, orderBy } from "lodash-es";
import {
  Inventory,
  InventoryBundleData,
  InventoryStatus,
} from "../../gql/graphql";

const getBundleById = (
  listBundlesInventories: Inventory[],
  bundleId: string
) => {
  const bundle = listBundlesInventories?.find(
    (bundle) => bundle.id === bundleId
  );
  return bundle;
};

const getBundleByInventory = (
  listBundlesInventories: Inventory[],
  inventoryId: string
) => {
  const bundle = listBundlesInventories?.find((inventory) =>
    (inventory.data as InventoryBundleData).items.includes(inventoryId)
  );
  return bundle;
};

const getOwnedBundles = (userInventories: Inventory[]) => {
  const ownedBundles = userInventories.filter((i) => i.isBundle);
  return ownedBundles;
};

const getOwnedItemsGroupByCategory = (userInventories: Inventory[]) => {
  const ownedItemsGroupByCategory = groupBy(
    userInventories.filter((i) => !i.isBundle),
    "category"
  );
  return ownedItemsGroupByCategory;
};

const getBundlesNotPurchase = (listBundlesInventories: Inventory[]) => {
  const ownedItemsGroupByCategory = orderBy(
    filter(listBundlesInventories, {
      isPurchased: false,
      status: InventoryStatus.Available,
    }),
    ["updatedAt"],
    ["desc"]
  );
  return ownedItemsGroupByCategory;
};

const getAvatarNotPurchase = (listInventories: Inventory[][]) => {
  const avatarNotPurchase = orderBy(
    compact(
      map(listInventories, (inventories) => {
        const avatars = filter(inventories, (inventory) => {
          return (
            [InventoryStatus.Available, InventoryStatus.Restricted].includes(
              inventory.status
            ) && !inventory.isPurchased
          );
        });
        if (avatars.length > 0) {
          return avatars;
        }
      })
    ),
    ["updatedAt"],
    ["desc"]
  );
  return avatarNotPurchase;
};

export const Inventories = {
  getBundleById,
  getBundleByInventory,
  getOwnedBundles,
  getOwnedItemsGroupByCategory,
  getBundlesNotPurchase,
  getAvatarNotPurchase,
};
