import apollo from "../../../clients/apollo";
import * as Apollo from "@apollo/client";
import { eventRulesGQL } from "../../queries/eventRules";
import { Query } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const getEventRules = async (
  options: Partial<Apollo.QueryOptions> = {}
): Promise<Query["eventRules"]> => {
  const res = await apolloClient.query({
    ...options,
    query: eventRulesGQL,
    variables: {},
  });

  return res?.data.eventRules;
};
