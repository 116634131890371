// TODO: remove these images from models
// However share model still use it so can't move it for now
import StatusIcon from "../../assets/rankGem/webp/Status_1x.webp";
import StatusIcon2x from "../../assets/rankGem/webp/icon2x/Status_2x.webp";
import StatusIcon3x from "../../assets/rankGem/webp/icon3x/Status_3x.webp";
import BeginnerIcon from "../../assets/rankGem/webp/Beginner_1x.webp";
import BeginnerIcon2x from "../../assets/rankGem/webp/icon2x/Beginner_2x.webp";
import BeginnerIcon3x from "../../assets/rankGem/webp/icon3x/Beginner_3x.webp";
import IntermediateIcon from "../../assets/rankGem/webp/intermediate1x.webp";
import IntermediateIcon2x from "../../assets/rankGem/webp/icon2x/Intermediate_2x.webp";
import IntermediateIcon3x from "../../assets/rankGem/webp/icon3x/Intermediate_3x.webp";
import AdvancedIcon from "../../assets/rankGem/webp/advanced_1x.webp";
import AdvancedIcon2x from "../../assets/rankGem/webp/icon2x/advanced_2x.webp";
import AdvancedIcon3x from "../../assets/rankGem/webp/icon3x/advanced_3x.webp";
import ExpertIcon from "../../assets/rankGem/webp/expert_1x.webp";
import ExpertIcon2x from "../../assets/rankGem/webp/icon2x/expert_2x.webp";
import ExpertIcon3x from "../../assets/rankGem/webp/icon3x/expert_3x.webp";
import MasterIcon from "../../assets/rankGem/webp/master1x.webp";
import MasterIcon2x from "../../assets/rankGem/webp/icon2x/Master_2x.webp";
import MasterIcon3x from "../../assets/rankGem/webp/icon3x/Master_3x.webp";
import ChampionIcon from "../../assets/rankGem/webp/champion_1x.webp";
import ChampionIcon2x from "../../assets/rankGem/webp/icon2x/champion_2x.webp";
import ChampionIcon3x from "../../assets/rankGem/webp/icon3x/champion_3x.webp";
import GrandChampionIcon from "../../assets/rankGem/webp/grand_champion_1x.webp";
import GrandChampionIcon2x from "../../assets/rankGem/webp/icon2x/grand_champion_2x.webp";
import GrandChampionIcon3x from "../../assets/rankGem/webp/icon3x/grand_champion_3x.webp";

import AdvancedBackground from "../../assets/resultCard/webp/rank1.webp";
import AdvancedBackground2x from "../../assets/resultCard/webp2x/rank1.webp";
import AdvancedBackground3x from "../../assets/resultCard/webp3x/rank1.webp";
import IntermediateBackground from "../../assets/resultCard/webp/rank2.webp";
import IntermediateBackground2x from "../../assets/resultCard/webp2x/rank2.webp";
import IntermediateBackground3x from "../../assets/resultCard/webp3x/rank2.webp";
import BeginnerBackground from "../../assets/resultCard/webp/rank3.webp";
import BeginnerBackground2x from "../../assets/resultCard/webp2x/rank3.webp";
import BeginnerBackground3x from "../../assets/resultCard/webp3x/rank3.webp";
import ExpertBackground from "../../assets/resultCard/webp/expert_bg_1x.webp";
import ExpertBackground2x from "../../assets/resultCard/webp2x/expert_bg_2x.webp";
import ExpertBackground3x from "../../assets/resultCard/webp3x/expert_bg_3x.webp";
import MasterBackground from "../../assets/resultCard/webp/rank0.webp";
import MasterBackground2x from "../../assets/resultCard/webp2x/rank0.webp";
import MasterBackground3x from "../../assets/resultCard/webp3x/rank0.webp";
import ChampionBackground from "../../assets/resultCard/webp/champion_bg_1x.webp";
import ChampionBackground2x from "../../assets/resultCard/webp2x/champion_bg_2x.webp";
import ChampionBackground3x from "../../assets/resultCard/webp3x/champion_bg_3x.webp";
import GrandChampionBackground from "../../assets/resultCard/webp/grand_champion_bg_1x.webp";
import GrandChampionBackground2x from "../../assets/resultCard/webp2x/grand_champion_bg_2x.webp";
import GrandChampionBackground3x from "../../assets/resultCard/webp3x/grand_champion_bg_3x.webp";

import StatusIconHeader from "../../assets/rankGem/webp/header/Status_1x.webp";
import StatusIconHeader2x from "../../assets/rankGem/webp/header/Status_2x.webp";
import StatusIconHeader3x from "../../assets/rankGem/webp/header/Status_3x.webp";
import BeginnerIconHeader from "../../assets/rankGem/webp/header/Beginner_1x.webp";
import BeginnerIconHeader2x from "../../assets/rankGem/webp/header/Beginner_2x.webp";
import BeginnerIconHeader3x from "../../assets/rankGem/webp/header/Beginner_3x.webp";
import IntermediateHeaderIcon from "../../assets/rankGem/webp/header/Intermediate_1x.webp";
import IntermediateHeaderIcon2x from "../../assets/rankGem/webp/header/Intermediate_2x.webp";
import IntermediateHeaderIcon3x from "../../assets/rankGem/webp/header/Intermediate_3x.webp";
import AdvancedIconHeader from "../../assets/rankGem/webp/header/Advanced_1x.webp";
import AdvancedIconHeader2x from "../../assets/rankGem/webp/header/Advanced_2x.webp";
import AdvancedIconHeader3x from "../../assets/rankGem/webp/header/Advanced_3x.webp";
import ExpertIconHeader from "../../assets/rankGem/webp/header/expert_1x.webp";
import ExpertIconHeader2x from "../../assets/rankGem/webp/header/expert_2x.webp";
import ExpertIconHeader3x from "../../assets/rankGem/webp/header/expert_3x.webp";
import MasterIconHeader from "../../assets/rankGem/webp/header/master_1x.webp";
import MasterIconHeader2x from "../../assets/rankGem/webp/header/master_2x.webp";
import MasterIconHeader3x from "../../assets/rankGem/webp/header/master_3x.webp";
import ChampionIconHeader from "../../assets/rankGem/webp/header/champion_1x.webp";
import ChampionIconHeader2x from "../../assets/rankGem/webp/header/champion_2x.webp";
import ChampionIconHeader3x from "../../assets/rankGem/webp/header/champion_3x.webp";

import { ILevel } from "../../types/user";
import { isNumber } from "lodash-es";
import { LevelValue, RankColor, RankGem } from "../../constants/user";
import { TierLevel } from "../../gql/graphql";

export const LEVEL_STAGE: ILevel[] = [
  {
    id: "unranked",
    name: RankGem.Unranked,
    color: RankColor.Unranked,
    rankColor: "",
    icon: {
      src1x: StatusIcon,
      src2x: StatusIcon2x,
      src3x: StatusIcon3x,
      header: {
        src1x: StatusIconHeader,
        src2x: StatusIconHeader2x,
        src3x: StatusIconHeader3x,
      },
    },
    xp: LevelValue.Unranked,
  },
  {
    id: "beginner",
    name: RankGem.Beginner,
    rankColor: "#924F00",
    color: RankColor.Beginner,
    icon: {
      src1x: BeginnerIcon,
      src2x: BeginnerIcon2x,
      src3x: BeginnerIcon3x,
      header: {
        src1x: BeginnerIconHeader,
        src2x: BeginnerIconHeader2x,
        src3x: BeginnerIconHeader3x,
      },
    },
    background: {
      src1x: BeginnerBackground,
      src2x: BeginnerBackground2x,
      src3x: BeginnerBackground3x,
    },
    xp: LevelValue.Beginner,
  },
  {
    id: "intermediate",
    name: RankGem.Intermediate,
    color: RankColor.Intermediate,
    rankColor: "#9caac3",
    icon: {
      src1x: IntermediateIcon,
      src2x: IntermediateIcon2x,
      src3x: IntermediateIcon3x,
      header: {
        src1x: IntermediateHeaderIcon,
        src2x: IntermediateHeaderIcon2x,
        src3x: IntermediateHeaderIcon3x,
      },
    },
    background: {
      src1x: IntermediateBackground,
      src2x: IntermediateBackground2x,
      src3x: IntermediateBackground3x,
    },
    xp: LevelValue.Intermediate,
  },
  {
    id: "advanced",
    name: RankGem.Advanced,
    color: RankColor.Advanced,
    rankColor: "#ffd648",
    icon: {
      src1x: AdvancedIcon,
      src2x: AdvancedIcon2x,
      src3x: AdvancedIcon3x,
      header: {
        src1x: AdvancedIconHeader,
        src2x: AdvancedIconHeader2x,
        src3x: AdvancedIconHeader3x,
      },
    },
    background: {
      src1x: AdvancedBackground,
      src2x: AdvancedBackground2x,
      src3x: AdvancedBackground3x,
    },
    xp: LevelValue.Advanced,
  },
  {
    id: "expert",
    name: RankGem.Expert,
    color: RankColor.Expert,
    rankColor: "#ED0000",
    icon: {
      src1x: ExpertIcon,
      src2x: ExpertIcon2x,
      src3x: ExpertIcon3x,
      header: {
        src1x: ExpertIconHeader,
        src2x: ExpertIconHeader2x,
        src3x: ExpertIconHeader3x,
      },
    },
    background: {
      src1x: ExpertBackground,
      src2x: ExpertBackground2x,
      src3x: ExpertBackground3x,
    },
    xp: LevelValue.Expert,
  },
  {
    id: "master",
    name: RankGem.Master,
    color: RankColor.Master,
    rankColor: "#23AEFD",
    icon: {
      src1x: MasterIcon,
      src2x: MasterIcon2x,
      src3x: MasterIcon3x,
      header: {
        src1x: MasterIconHeader,
        src2x: MasterIconHeader2x,
        src3x: MasterIconHeader3x,
      },
    },
    background: {
      src1x: MasterBackground,
      src2x: MasterBackground2x,
      src3x: MasterBackground3x,
    },
    xp: LevelValue.Master,
  },
  {
    id: "champion",
    name: RankGem.Champion,
    color: RankColor.Champion,
    rankColor: "#9047F5",
    icon: {
      src1x: ChampionIcon,
      src2x: ChampionIcon2x,
      src3x: ChampionIcon3x,
      header: {
        src1x: ChampionIconHeader,
        src2x: ChampionIconHeader2x,
        src3x: ChampionIconHeader3x,
      },
    },
    background: {
      src1x: ChampionBackground,
      src2x: ChampionBackground2x,
      src3x: ChampionBackground3x,
    },
    xp: LevelValue.Champion,
  },
  {
    id: "grand-champion",
    name: RankGem.GrandChampion,
    color: RankColor.GrandChampion,
    rankColor: "",
    icon: {
      src1x: GrandChampionIcon,
      src2x: GrandChampionIcon2x,
      src3x: GrandChampionIcon3x,
      header: {
        src1x: GrandChampionIcon,
        src2x: GrandChampionIcon2x,
        src3x: GrandChampionIcon3x,
      },
    },
    background: {
      src1x: GrandChampionBackground,
      src2x: GrandChampionBackground2x,
      src3x: GrandChampionBackground3x,
    },
    xp: LevelValue.GrandChampion,
  },
];

export const formatLevelStage = (tierLevels?: TierLevel[]) => {
  if (!tierLevels) return LEVEL_STAGE;

  const getLevelXp = (id: string) => {
    const currentLevel = tierLevels?.find((tier) => {
      return tier.levelId === id;
    });
    return currentLevel?.amount || 0;
  };

  const levelFormatted = LEVEL_STAGE.map((level) => {
    return { ...level, xp: getLevelXp(level.id) };
  });

  return levelFormatted;
};

export const getCurrentRank = (
  xp?: number,
  tierLevels?: TierLevel[]
): ILevel => {
  const levelStage = formatLevelStage(tierLevels);
  if (!xp || xp < 1)
    return {
      ...levelStage[0],
      currentXp: xp,
      levelIndex: 0,
      nextLevelXp: levelStage[1].xp,
      levelPercent: 0,
      nextLevelXpNeeded: levelStage[1].xp - (xp || 0),
    };

  const filterLevelPass = levelStage.filter((level) => {
    return xp >= level.xp;
  });
  const levelIndex = filterLevelPass.length - 1;
  const nextLevelXp = levelStage[levelIndex + 1]?.xp;
  const levelPercent =
    (xp - levelStage[levelIndex].xp) /
    (nextLevelXp - levelStage[levelIndex].xp);

  return {
    ...filterLevelPass[levelIndex],
    currentXp: xp - levelStage[levelIndex].xp,
    levelIndex: levelIndex,
    nextLevelXp: nextLevelXp,
    levelPercent: nextLevelXp ? levelPercent * 100 : 100,
    nextLevelXpNeeded: nextLevelXp - levelStage[levelIndex].xp,
  };
};

export const getNextRank = (id?: string, tierLevels?: TierLevel[]) => {
  const levelStage = formatLevelStage(tierLevels);
  const levelIndex = levelStage.findIndex((level) => level.id === id);
  return levelStage[levelIndex + 1] || levelStage[levelIndex];
};

export const isLevelUp = (currentLevel: ILevel, xpReceive: number) => {
  if (
    !isNumber(currentLevel.currentXp) ||
    !isNumber(currentLevel.nextLevelXpNeeded)
  )
    return false;
  return currentLevel.currentXp + xpReceive >= currentLevel.nextLevelXpNeeded;
};

export const getFormatUsername = (
  username: string,
  max: number,
  nbShowCharacter: number
) => {
  if (username?.length > max) {
    return `${username.slice(0, nbShowCharacter)}..`;
  }
  return username;
};
