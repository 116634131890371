import { Moment } from "./moment";
import { IMomentData } from "../types/moment";

export class MomentFactory {
  static isMomentTime = (type?: string) => {
    return type && type.toLowerCase() === "time";
  };

  static getIndexOfAvailableMoment = (momentData: IMomentData) => {
    if (!momentData.allMoments) return 0;
    const index = momentData.allMoments.findIndex(
      (o: Moment) => o.id === momentData.listNotCompleted?.[0]?.id
    );
    return index > 0 ? index : 0;
  };

  static getCurrentMomentSlide = (
    currentSlide: number,
    nextSlide: number,
    momentData: IMomentData
  ) => {
    let leftItem;
    let midItem;
    let rightItem;
    if (momentData.allMoments) {
      if (currentSlide < nextSlide) {
        leftItem = momentData.allMoments[currentSlide];
        midItem = momentData.allMoments[nextSlide];
        rightItem = momentData.allMoments[nextSlide + 1];
      } else {
        leftItem = momentData.allMoments[nextSlide - 1];
        midItem = momentData.allMoments[nextSlide];
        rightItem =
          nextSlide === 0
            ? momentData.allMoments[1]
            : momentData.allMoments[currentSlide];
      }
    }

    return { leftItem, midItem, rightItem };
  };
}
