import { PageRoutes } from "./historyOnmo";

export const BOTTOM_NAVIGATION = [
  {
    id: "home",
    title: "common_Home",
    path: PageRoutes.HomePage,
    icon: "icon-linear-home",
    iconActive: "icon-bold-home",
  },
  {
    id: "games",
    title: "common_Games",
    path: PageRoutes.Games,
    icon: "icon-linear-box",
    iconActive: "icon-bold-box",
  },
  {
    id: "leaderboard",
    title: "common_Leaderboard",
    path: PageRoutes.Leaderboard,
    icon: "icon-linear-ranking",
    iconActive: "icon-bold-ranking",
  },
  {
    id: "friends",
    title: "common_Friends",
    path: PageRoutes.Friends,
    icon: "icon-linear-profile-2user",
    iconActive: "icon-bold-profile-2user",
  },
];
