import { IFullTheme } from "../types/theme";
import { DarkThemeColors } from "../constants/colors";

export const DEFAULT_THEME: IFullTheme = {
  theme: {
    palette: {
      primary: {
        main: DarkThemeColors.Primary,
      },
      secondary: {
        main: DarkThemeColors.Secondary,
      },
    },
    textStyle: "default",
    fontFamily: "Roboto, sans-serif",
  },
  app: {
    name: "ONMO",
    brandName: "ONMO",
    feature: {
      forceFullscreenOnFirstTouch: true,
      enablePWA: true,
    },
  },
  pages: {
    login: {
      template: {
        logo1: "logo-template.webp",
        hasTitle: true,
        hasTextCheckbox: true,
        freeCoins: "1000",
      },
    },
    splash: {
      backgroundColor: DarkThemeColors.Background1,
      hasLoadingBar: true,
    },
    shop: {
      showOnmoShop: true,
    },
    welcome: {
      showOnboardingGames: true,
      showOnboardingIntro: true,
      gameOnboardingFlow: "1",
    },
    game: {
      showPlayerSurvey: false,
    },
  },
  legalDocuments: {
    en: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vSu2xeDf4tkJVeJpStHHYP7iGAnIk7CKs-s6N1d2G17wy8D7_tCPx88WgujjUFr9kk9Vmpk6DjiPCEM/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vQCVraHSKZ1sfZhZW6jIh3CtPbgJv06wk72QeTjNiFQSfxThalx6CY1u2ygzjUmP_S7x8Oge4I6tmRT/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vQzyoOSvJLlGbzz3qRt9wkbrtoWoqpEPmLtXBJWJCeaEMSSdOeOo58KyJ1TnKskNwj1LCSgD59XdLDm/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
    ar: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vRPfoKyZAir3AEQHSn4rdO1bMLeQw5Ya2X-itFHK06zVxrLZWJQPmQpepfRM7sf-Q/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vRja1eAJxXArv4EpRt3UhFDQRWH2BkHKhWzqypZ9Icv-RGNYUSKl2me8rdAnP8TDZE72-1boF-Vd1zd/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vS02P-HfCA2aSGeW9ExJLEXvHuOF6BYuF6q77hDJlkGgDSSaakDFeGAe9UsjU7kwz5WMDHb59WXuSVg/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
    es: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vR_r6wR7Zy5kKxurBHeS_Fk06lUlGv0053w0mv4wfp9gmK8uq6N9go3QOxE3F8ViD8EaQdniBgHdmb_/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vSg9ArYcpEY5PRDSd2smqTv8-Ts7OXprZoxTeH8zJgwXuQfyoGSb2z2XK6gf6i3eOUa6rxqmDq8L6tp/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vQVZrSC56Ivlr4iOXwJDi8Acqg4ecCUqV1WXtC4jswPyrkenSeDYdQHnbnZZggAbsmKepkZeUza-6Mq/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
    fr: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vQihZRV6BmuyLtiAkSVe_lKU8EH1xLdvI1kbMVYktOm3iLg4zWtZj47taEfUuhk0aLblLNnoJ5eUvwM/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vQ2OpjIQJw6RaTKl6d6uMLqydA7_HTsZhC8fx-B25UQnkOb2sO3VtoA_0BkG1NAE6lW4R5XSPJKhLRD/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vSFI8p_VcOSPrsRN7M-EHh-cNESfoFxEDyTKvzKV12DqzLYNGWh6OohYIDYVfk4Axz7I8rIcfuYLr1d/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
    th: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vQmZqWXmAJ8rSU05FviIGKKqdb1rhBwvieSSnRmdxp1FmwldZTslt2HFDnLsdsC2g/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vR2s8AKS9zoySDgBuIyDB_-zg2tLNp6TUlRFh_UCDAnXti50OypZE-aSxTx6CgFWA/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vTTsPsuTJLyzrql45amtJ8uZkRXbO7I39d7oHVEIaChYGqxsN6LcE5CGga7nwmFfw/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
    am: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vTb8RA7KMLkuSb7Y_lZH63TIfE7yyAzxY6ylq8RaSm97URC8o7xPCnLR2HJrV1JOQ/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vQBK_3X7mn7WYihQ434I4_pSah38xbgtOTrBgIxxVzJOA-Fs28DY0XxkrYTLEVMDA/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vQ865DZJAU9LtzkVeI5BuEdKVCL7rH9L3CmW12cmqVSn37X26Qx_Lm5FF0CfpuhUA/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
    km: {
      faq: "https://docs.google.com/document/d/e/2PACX-1vQE6_UuayWFnnuwr2wpWlC5V-ZFlzTla38LfF7xdj65z3lFWcWV3Lu_bg_Sz1LgGA/pub?embedded=true",
      termsAndConditions:
        "https://docs.google.com/document/d/e/2PACX-1vTPK3Mlp5Xh1b4ON9HDF4paJWrUETmB_y8k_qNcufsV8bek4tCbqtFrot5HZHdt8w/pub?embedded=true",
      privacyPolicy:
        "https://docs.google.com/document/d/e/2PACX-1vQGYB1U5CETCySh8kB9Orqu5opO2yEaKb1gJsdxFwdE8iV6pKyXtHpc9R8BJmVK7Q/pub?embedded=true",
      aboutUs: "https://www.onmo.com/",
    },
  },
};
