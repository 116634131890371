import { gql } from "graphql-tag";

export const playSoloMomentGQL = gql`
  mutation PlaySoloMoment($playSoloMomentInput: PlaySoloMomentInput) {
    playSoloMoment(playSoloMomentInput: $playSoloMomentInput) {
      status
    }
  }
`;

export const endHtmlGameSessionGQL = gql`
  mutation EndHtmlGameSession(
    $endHtmlGameSessionInput: EndHtmlGameSessionInput!
  ) {
    endHtmlGameSession(endHtmlGameSessionInput: $endHtmlGameSessionInput) {
      status
    }
  }
`;

export const enterSoloMomentGQL = gql`
  mutation EnterSoloMoment($enterSoloMomentInput: EnterSoloMomentInput) {
    enterSoloMoment(enterSoloMomentInput: $enterSoloMomentInput) {
      gameSessionId
      edgeNodeId
    }
  }
`;

export const startLivestreamGQL = gql`
  mutation StartLivestream($startLivestreamInput: StartLivestreamInput!) {
    startLivestream(startLivestreamInput: $startLivestreamInput) {
      battleId
      battleType
      currentPlayer {
        avatar
        id
        username
      }
      edgeNodeId
      gameSessionId
      host {
        avatar
        id
        username
      }
      id
      status
      viewers {
        avatar
        id
        username
      }
    }
  }
`;

export const joinLivestreamGQL = gql`
  mutation JoinLivestream($joinLivestreamInput: JoinLivestreamInput!) {
    joinLivestream(joinLivestreamInput: $joinLivestreamInput) {
      battleId
      battleType
      currentPlayer {
        avatar
        id
        username
      }
      edgeNodeId
      gameSessionId
      host {
        avatar
        id
        username
        xp
      }
      id
      status
      viewers {
        avatar
        id
        username
      }
    }
  }
`;
