import { compact, orderBy, uniqBy } from "lodash-es";
import { listLivestreams } from "../graphql/resolvers/queries/liveStream";
import { IPlayer } from "../types/liveStream";
import { MomentFactory } from "./momentFactory";
import { Time } from "./time";
import { QueryOptions } from "@apollo/client";
import { VIEWER_SESSION_SUB_STATES } from "../slices/liveStream";
import { FollowStatus, Livestream, PublicUser } from "../gql/graphql";
import { TFunction } from "i18next";

export class LiveStreamFactory {
  static listLiveStreams = async (options: Partial<QueryOptions> = {}) => {
    const data = await listLivestreams(options);
    return data;
  };

  static getScoreTransition = (scorePercentage: number) => {
    const style = { insetInlineEnd: `0%`, insetInlineStart: "unset" };
    style.insetInlineStart =
      scorePercentage < 10 ? `0%` : `${scorePercentage - 10}%`;

    return style;
  };

  static isOvercomeScore = (score: number, scoreToBeat: number) => {
    return score > scoreToBeat;
  };

  static getPlayerScore = (gameScore: number, momentType?: string) => {
    if (!MomentFactory.isMomentTime(momentType)) {
      return gameScore;
    }
    return Time.formatDurationScore(gameScore);
  };

  static isStreamEnded = (state: number) => {
    return state === VIEWER_SESSION_SUB_STATES.STREAM_ENDED;
  };

  static isStreamFull = (state: number) => {
    return state === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_FULL_SEATS;
  };

  static isErrorReconnect = (state: number) => {
    return state === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_RECONNECT;
  };

  static orderByTypeAndStartAt = (listLiveStreams: Livestream[]) => {
    return orderBy(listLiveStreams, ["status", "startedAt"], ["desc", "desc"]);
  };

  static convertLiveTime = (startedAt: string, t: TFunction) => {
    const pastDate = new Date(startedAt);
    const now = new Date();
    const diff = now.getTime() - pastDate.getTime();
    const diffMinutes = diff < 60000 ? 1 : Math.floor(diff / 60000);
    const diffHours = Math.floor(diff / 3600000);
    const diffDays = Math.floor(diff / 86400000);

    if (diffDays > 0) {
      return diffDays > 1
        ? t("common_number day ago_other", { count: diffDays })
        : t("common_number day ago_one", { count: diffDays });
    } else if (diffHours > 0) {
      return t("{{x}} hour ago", { x: diffHours });
    } else if (diffMinutes > 0) {
      return t("{{x}} min ago", { x: diffMinutes });
    }
  };

  static isLiveCrash = (state: number) => {
    return state === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_CRASH;
  };

  static getListViewerUniq = (viewers: IPlayer[]) => {
    return uniqBy(viewers, "id");
  };

  static getDifferenceViewer = (
    viewers: IPlayer[],
    newViewers: IPlayer[],
    viewerControl?: IPlayer,
    host?: IPlayer
  ) => {
    const findViewer = (id: string) => {
      return newViewers.find((newViewer) => newViewer.id === id);
    };
    return (
      viewers.filter(
        (viewer) =>
          !findViewer(viewer.id) &&
          viewer.id !== viewerControl?.id &&
          viewer.id !== host?.id
      ) || []
    );
  };

  static getListPlayer = (
    viewers: IPlayer[],
    viewerControl?: IPlayer,
    host?: IPlayer
  ) => {
    return compact(uniqBy([host, viewerControl].concat(viewers), "id"));
  };

  static getListViewerId = (listViewer: IPlayer[], myId?: string) => {
    return listViewer.reduce((acc: string[], user: IPlayer) => {
      if (user.id !== myId) acc.push(user.id);
      return acc;
    }, []);
  };

  static getNewListFollow = (
    listViewerFollowStatus?: PublicUser[],
    viewerId?: string
  ) => {
    return listViewerFollowStatus?.map((item) => {
      if (item.id === viewerId) {
        return { ...item, followStatus: FollowStatus.Following };
      }
      return item;
    });
  };
}
