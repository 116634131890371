import { createUseStyles } from "react-jss";
import { ZIndex } from "../constants/zIndex";

export const useStyles = createUseStyles({
  contentBlock: {
    height: "100%",
    padding: 15,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: ZIndex.dialog + 1,
  },
});
