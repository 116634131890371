import { gql } from "graphql-tag";

export const followUserGQL = gql`
  mutation FollowUser($followUserInput: FollowInput!) {
    followUser(followUserInput: $followUserInput) {
      status
    }
  }
`;

export const unfollowUserGQL = gql`
  mutation UnfollowUser($unfollowUserInput: FollowInput!) {
    unfollowUser(unfollowUserInput: $unfollowUserInput) {
      status
    }
  }
`;

export const createFriendRequestCodeGQL = gql`
  mutation Mutation {
    createFriendRequestCode {
      oncode
    }
  }
`;
