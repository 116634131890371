export enum AnalyticsCategory {
  Battle = "battle",
  Casual = "casual",
  DiscoverPage = "discoverpage",
  FriendPage = "friendspage",
  GameDetail = "gamedetailpage",
  Login = "login",
  Moment = "moment",
  MenuPage = "menupage",
  Profile = "profile",
  Signup = "signup",
  Install = "install",
  NotifyPopup = "Notifpopup",
  Battle1v1 = "1v1battle",
  Tournament = "tournament",
  Wallet = "wallet",
  Shop = "shop",
  Onboarding = "onboarding",
  GameList = "gamelistingpage",
  TournamentStream = "tournament_stream",
}

export enum AnalyticsEvent {
  // LOGIN
  AuthLanding = "auth_landing",
  SignUp = "sign_up",
  Login = "login",
  AuthClick = "auth_click",

  // ONBOARDING
  OnboardIntroView = "onboard_intro_view",
  OnboardIntroSwipe = "onboard_intro_swipe",
  OnboardIntroEnd = "onboard_intro_end",
  OnboardGameSelectView = "onboard_game_select_view",
  OnboardGameSelectSwipe = "onboard_game_select_swipe",
  OnboardGameSelectSkip = "onboard_game_select_skip",
  OnboardGameSelectStart = "onboard_game_select_start",
  OnboardGameLoadAnimation = "onboard_game_load_animation",
  OnboardGameVideoView = "onboard_game_video_view",
  OnboardGameVideoEnd = "onboard_game_video_end",
  OnboardGameStart = "onboard_game_start",
  OnboardGameQuit = "onboard_game_quit",
  OnboardGameEnd = "onboard_game_end",
  OnboardComplete = "onboard_complete",

  // MOMENT
  MomentLoad = "moment_load",
  MomentStart = "moment_start",
  MomentEnd = "moment_end",
  MomentQuit = "moment_quit",

  // STATUS UPDATES
  StatusLevelUp = "status_level_up",

  // CURRENCY
  EarnVirtualCurrency = "earn_virtual_currency",
  SpendVirtualCurrency = "spend_virtual_currency",
  InsufficientFunds = "insufficient_funds",

  // SHARE
  Share = "share",

  // UI Popups and Navigation
  TournamentResult = "tournament_result",
  ViewGame = "view_game",
  WatchTutorial = "watch_tutorial_vid",
  Search = "search",
  NotificationRequest = "notification_request",
  NotificationResponse = "notification_response",
  InstallApp = "install_app",
  Logout = "logout",
  DeleteAccount = "delete_account",
  GoLiveTapped = "go_live_button_tapped",

  // LIVE STREAMS
  LiveChallengeWatch = "live_challenge_watch",

  // PRODUCT PERFORMANCE
  WebVitalsFcp = "web_vitals_fcp",
  WebVitalsLcp = "web_vitals_lcp",
  WebVitalsFid = "web_vitals_fid",
  WebVitalsCls = "web_vitals_cls",
  WebVitalsTtfb = "web_vitals_ttfb",
}

export enum WebVitalsName {
  FCP = "FCP",
  LCP = "LCP",
  FID = "FID",
  CLS = "CLS",
  TTFB = "TTFB",
}
