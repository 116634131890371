import apollo from "../../../clients/apollo";
import {
  getUserProfileGQL,
  getUserV2GQL,
  getConfigGQL,
  getUserMetadataGQL,
  coinWalletsGQL,
  getUnseenRewardTransactionsGQL,
  loginRewardsGQL,
  searchUserGQL,
  getUserStreakGQL,
} from "../../queries/users";
import { getUserB2bSubscriptionGQL } from "../../queries/subcriptionb2b";

import { QueryOptions } from "@apollo/client";
import {
  FullTransaction,
  Query,
  UserProfile,
  UserSubscription,
} from "../../../gql/graphql";

const apolloClient = apollo.getInstance();
interface ITotalCount {
  totalCount: number;
}
interface IGetUserProfileResponse {
  getUserProfile: UserProfile;
  following: ITotalCount;
  followers: ITotalCount;
}

export const getUserMetadata = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["getUserMetadata"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserMetadataGQL,
  });

  return res.data.getUserMetadata;
};

export const getUserProfile = async (
  userId: string,
  options: Partial<QueryOptions> = {}
): Promise<IGetUserProfileResponse> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserProfileGQL,
    variables: {
      userProfileInput: { userId },
      followingInput: { userId },
      followersInput: { userId },
    },
  });

  if (res?.errors) {
    throw res?.errors[0];
  }

  return res.data;
};

export const getUserBattleStreak = async (
  userId: string,
  options: Partial<QueryOptions> = {}
): Promise<Query["getUserProfile"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserStreakGQL,
    variables: { userProfileInput: { userId } },
  });

  if (res?.errors) {
    throw res?.errors[0];
  }
  return res.data.getUserProfile;
};

export const getUserV2 = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["getUserV2"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserV2GQL,
    variables: {},
  });

  return res.data.getUserV2;
};

export const getUnseenRewardTransaction = async (
  options: Partial<QueryOptions> = {}
): Promise<FullTransaction[]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUnseenRewardTransactionsGQL,
    variables: {},
  });

  return res.data.getUserV2.unseenRewardTransactions;
};

export const getUserB2bSubscription = async (
  options: Partial<QueryOptions> = {}
): Promise<UserSubscription> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserB2bSubscriptionGQL,
    variables: {},
  });

  return res.data.getUserV2.subscription;
};

export const getConfig = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["config"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getConfigGQL,
  });

  return res.data.getConfig;
};

export const getCoinWallets = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["coinWallets"]> => {
  const coinWallets = await apolloClient.query({
    ...options,
    query: coinWalletsGQL,
  });

  return coinWallets.data.coinWallets;
};

export const getLoginRewards = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["loginRewards"]> => {
  const loginRewards = await apolloClient.query({
    ...options,
    query: loginRewardsGQL,
  });

  return loginRewards.data.loginRewards;
};

export const searchUser = async (
  params: { username?: string; userIds?: string[] },
  options: Partial<QueryOptions> = {}
): Promise<Query["users"]> => {
  const res = await apolloClient.query({
    ...options,
    query: searchUserGQL,
    variables: { searchUsersInput: params },
  });

  return res.data.users;
};
