import { gql } from "graphql-tag";

export const getUserMetadataGQL = gql`
  query GetUserMetadata {
    getUserMetadata {
      onboarding
      hasReceivedWelcomeCoins
      preferredGameCategories
      onboarding
      lastSeenTransactionId
      desiredStreamResolution
    }
  }
`;

export const getUserProfileGQL = gql`
  query GetUserProfile(
    $userProfileInput: UserProfileInput!
    $followingInput: FollowersInput!
    $followersInput: FollowersInput!
  ) {
    getUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        username
        avatar
        xp
      }
      friendStatus
      friendRequestId
      nbMatchesWon
      challengesCompleted
      nbFriends
      winningPercent
      allTimeRank
      lastCheckin
      followStatus
      battleStreakData {
        battleStreak
        bestBattleStreak
      }
    }
    following(followingInput: $followingInput) {
      totalCount
    }
    followers(followersInput: $followersInput) {
      totalCount
    }
  }
`;

export const getUserStreakGQL = gql`
  query GetUserProfile($userProfileInput: UserProfileInput!) {
    getUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        username
        avatar
        xp
      }
      battleStreakData {
        battleStreak
        bestBattleStreak
      }
    }
  }
`;

export const getUserV2GQL = gql`
  query GetUserV2 {
    getUserV2 {
      user {
        id
        idpId
        idpName
        cheatStatus
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        hasWelcome
        currencyLocked
        wallets {
          coinType
          balance
        }
        language
      }
      nbNotificationsUnread
      nbFriendRequestUnaccepted
      nbBattlesPending
      permissionGroups
      firstTime
      whileAway {
        eventType
        momentType
        gameId
        matchId
        tournamentId
        title
        amount
        playerScore
        opponentScore
        currency
        avatar
        username
      }
    }
  }
`;

export const getUnseenRewardTransactionsGQL = gql`
  query GetUnseenRewardTransactions {
    getUserV2 {
      unseenRewardTransactions {
        amount
        category
        createdAt
        currency
        entryId
        expiryDate
        gameId
        gameTitle
        reference
        runningBalance
        status
        transactionType
        type
        walletType
        workflow
      }
    }
  }
`;

export const getConfigGQL = gql`
  query GetConfig {
    getConfig {
      freeCoins {
        amount
      }
      prizePools {
        battle {
          coinType
          coinsLoser
          coinsWinner
        }
        challenge {
          coinsCompletion
          coinType
          coinsFailure
          coinsFirstTimeCompletion
        }
      }
      tierLevels {
        amount
        levelId
      }
    }
  }
`;

export const coinWalletsGQL = gql`
  query CoinWallets {
    coinWallets {
      items {
        balance
        coinType
        userId
      }
      totalCount
    }
  }
`;

export const loginRewardsGQL = gql`
  query {
    loginRewards {
      __typename
      ... on CoinLoginRewardProgress {
        walletType
        itemType
        amount
        status
      }
      ... on AchievementLoginRewardProgress {
        walletType
        achievementItemType: itemType
        amount
        status
      }
    }
  }
`;

export const searchUserGQL = gql`
  query Users($searchUsersInput: SearchUsersInput!) {
    users(searchUsersInput: $searchUsersInput) {
      items {
        id
        username
        avatar
        xp
        lastCheckin
        followStatus
      }
    }
  }
`;
