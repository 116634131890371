import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { Query } from "../../../gql/graphql";
import { getGlobalLeaderboardProfileGQL } from "../../queries/globalLeaderboard";

const apolloClient = apollo.getInstance();

export const getGlobalLeaderboardProfile = async (
  endDate: string,
  leaderboardType: string,
  options: Partial<QueryOptions> = {}
): Promise<Query["globalLeaderboard"]> => {
  const response = await apolloClient.query({
    ...options,
    query: getGlobalLeaderboardProfileGQL,
    variables: {
      globalLeaderboardInput: {
        endDate: endDate,
        leaderboardType: leaderboardType,
      },
    },
  });

  return response.data.globalLeaderboard;
};
