import { listTournaments } from "../graphql/resolvers/queries/tournament";
import { filter, orderBy, sortBy } from "lodash-es";
import { ILeaderboard } from "../types/tournament";
import { IPlayer } from "../types/liveStream";
import { TournamentCategories, TournamentType } from "../constants/tournaments";
import { QueryOptions } from "@apollo/client";
import { RankOrder, TournamentInfo, UserV2 } from "../gql/graphql";

export class TournamentsFactory {
  static list = async (options: Partial<QueryOptions> = {}, limit = 20) => {
    const tournamentsList = await listTournaments(options, limit);
    return tournamentsList;
  };
  static getTop3PlayerNearMe = (
    leaderboards: ILeaderboard[],
    userId: string
  ) => {
    if (leaderboards.length <= 3) return leaderboards;

    const index = leaderboards.findIndex((o) => o.user.id === userId);
    let result = [];
    if (index === 0 || index === -1) {
      result = leaderboards.slice(0, 3);
    } else if (index === leaderboards.length - 1) {
      result = leaderboards.slice(-3);
    } else {
      result = [
        leaderboards[index - 1],
        leaderboards[index],
        leaderboards[index + 1],
      ];
    }
    return result;
  };

  static getPlayerRank = (leaderboards: ILeaderboard[], playerId: string) => {
    const index = leaderboards?.findIndex((p) => p.user.id === playerId);
    return index + 1;
  };

  static updateLbByScore = (
    leaderboards: ILeaderboard[],
    nbScore: number,
    user: UserV2 | IPlayer,
    rankOrder: string | undefined
  ) => {
    const leaderboardsWithOutScore = leaderboards.filter(
      (player) => player.user.id !== user.id && player.score <= 0
    );
    const myLb = {
      score: nbScore <= 0 ? 0 : nbScore,
      user: { id: user.id, username: user.username, avatar: user.avatar },
    } as ILeaderboard;

    const newLb = leaderboards
      .reduce((acc: ILeaderboard[], cur: ILeaderboard) => {
        if (cur.user.id !== user.id && cur.score > 0) acc.push(cur);
        return acc;
      }, [])
      .concat(myLb);

    const sortLb = sortBy(newLb, "score", (a) => a.user.id !== user.id);

    if (rankOrder === RankOrder.Asc) {
      sortLb.concat(leaderboardsWithOutScore);
    } else {
      sortLb.reverse().concat(leaderboardsWithOutScore);
    }

    const playerIndex = this.getPlayerRank(sortLb, user.id);

    if (playerIndex === 10) return playerIndex + 1;
    return playerIndex;
  };

  static isBeatIt = (tournamentType: string) => {
    return (
      tournamentType === TournamentType.PracticeHtml ||
      tournamentType === TournamentType.PracticeStream
    );
  };

  static getListTournamentSorted = (tournaments: TournamentInfo[] | null) => {
    if (!tournaments) return [];
    const availableTournament = filter(tournaments, (t) => {
      return (
        new Date(t.expiredAt).getTime() > new Date().getTime() &&
        t.status === "OPEN"
      );
    });

    const orderByFeature = orderBy(
      availableTournament,
      (t) => t.tournamentType.includes(TournamentCategories.Featured),
      ["desc"]
    );

    return orderByFeature;
  };
}
