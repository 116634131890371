export enum SessionType {
  Casual = "Casual",
  Challenge = "Challenge",
  Battle = "Battle",
  Tutorial = "Tutorial",
  Tournament = "Tournament",
  BeatIt = "Beat It",
  Solo = "Solo",
  FriendBattle = "Friends battle",
  LiveChallenge = "Live Challenge",
}

export enum StreamStatus {
  None = "",
  Connecting = "CONNECTING",
  Ready = "READY",
  VideoCanNotPlay = "VIDEO_CANNOT_PLAY",
  RequireInteraction = "REQUIRE_INTERACTION",
  OutOfCapacity = "OUT_OF_CAPACITY",
  Paused = "PAUSED",
  Resumed = "RESUMED",
  Waiting = "WAITING",
  Disconnected = "DISCONNECTED",
  VideoCanPlay = "VIDEO_CAN_PLAY",
  Unreachable = "UNREACHABLE",
  EdgeNodeCrashed = "EDGE_NODE_CRASHED",
  Expired = "EXPIRED",
  HasFinalScore = "HAS_FINAL_SCORE",
  ConfirmToLeave = "CONFIRM_TO_LEAVE",
  Done = "DONE",
  ShowEndScreen = "SHOW_END_SCREEN",
  StreamTerminated = "stream-terminated",
  StreamConnected = "stream-connected",
}

export const PAGE_CSS_STYLES = {
  html: {
    position: "fixed",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    padding: "0px",
    overflow: "hidden",
    overscrollBehaviorY: "none",
    overscrollBehaviorX: "none",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
  },
};

export enum StreamVolume {
  Muted = 0,
  Unmuted = 0.5,
}

export enum StepEndGameSession {
  Loading = 0,
  ResultScore = 1,
  CurrentRank = 2,
  NextRank = 3,
}

export enum GameSessionAmount {
  Challenge = 50,
  Battle = 50,
}
