import React, {
  FC,
  HTMLAttributes,
  useState,
  useEffect,
  Suspense,
} from "react";
import { useLocation } from "react-router-dom";
import RoutesNotLogged from "./RoutesNotAuth";
import { OnmoStorage } from "../models/onmoStorage";
import { initializeI18N } from "../i18n";
import i18next from "i18next";
import { Translations } from "../models/translations";
import { OnmoLoading } from "../components/common/OnmoLoading";
import useEventListener from "../hooks/useEventListener";
import { QueryParams } from "../models/queryParam";
import { requestFullScreen } from "../models/screen";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  CognitoUserState,
  currentAuthenticatedUser,
  isSignedIn,
} from "../slices/cognitoUser";
import { setPercentage } from "../slices/splashScreen";
import { Alert } from "../components/core";
import { ErrorScreen } from "../components/common/ErrorScreen";
import { PopupType } from "../constants/popup";
import { AccountBlocked } from "../components/common/AccountBlocked";
import { isAccountDisable } from "../models/users/users";
import { useStyles } from "./RoutesLogged.style";
import { HistoryOnmo } from "../models/historyOnmo";
import { setTextStyle } from "../slices/theme";
import { TextStyleByLanguage } from "../constants/user";
import { System } from "../models/system";

const RoutesLoggedWithProvider = React.lazy(
  () => import("./RoutesLoggedWithProvider")
);

export const Routing: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const cognitoUserStatus = useAppSelector((state) => state.cognitoUser.status);
  const { theme, isThemeLoaded } = useAppSelector((state) => state.theme);
  const { app, pages } = theme;
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isI18NLoaded, setIsI18NLoaded] = useState(false);
  const [isAccountBlock, setIsAccountBlock] = useState(false);
  const isMaintenance = useAppSelector((state) => state.maintenance.isEnabled);
  const maintenanceDetails = useAppSelector((state) => state.maintenance);
  const alert = useAppSelector((state) => state.alert);
  const signedIn = useAppSelector(isSignedIn);

  const onRedirectToOnmoPay = () => {
    if (
      pages?.login?.isOnmoPayRedirect &&
      pages?.login?.onmoPayUrl &&
      cognitoUserStatus === CognitoUserState.SIGNOUT &&
      !HistoryOnmo.isSigninProviderPage(location.pathname) &&
      System.isProd &&
      !isMaintenance
    ) {
      // B2B automatic redirect to onmoPay login
      OnmoStorage.setLoginType("onmoPay");
      window.location.href = `${pages?.login
        ?.onmoPayUrl}&lang=${Translations?.getDefaultLanguage()}`;
    }
  };

  const onBackLogin = () => {
    setIsAccountBlock(false);
  };

  useEffect(() => {
    onRedirectToOnmoPay();
  }, [signedIn, isMaintenance, theme, cognitoUserStatus]);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine && !isOnline) {
      window.location.reload();
    }
  };

  useEventListener("online", updateOnlineStatus, [isOnline]);
  useEventListener("offline", updateOnlineStatus, [isOnline]);

  useEffect(() => {
    const onLoaded = () => {
      setIsI18NLoaded(true);
    };
    const onLanguageChanged = (lang: string) => {
      dispatch(setTextStyle(TextStyleByLanguage[lang] || "default"));
    };
    i18next.on("loaded", onLoaded);
    i18next.on("languageChanged", onLanguageChanged);
    initializeI18N();
    return () => {
      i18next.off("loaded", onLoaded);
      i18next.off("languageChanged", onLanguageChanged);
    };
  }, []);

  useEffect(() => {
    const onCode = QueryParams.getOncode();
    const state = QueryParams.getState();
    if (state) {
      OnmoStorage.setState(state);
    }
    if (onCode) {
      OnmoStorage.setOncode(onCode);
    }

    dispatch(currentAuthenticatedUser())
      .then(() => {
        dispatch(setPercentage({ percentage: 50 }));
      })
      .catch((error) => {
        if (isAccountDisable((error as Error)?.message)) {
          dispatch(setPercentage({ percentage: 100 }));
          setIsAccountBlock(true);
        }
      });
  }, []);

  useEffect(() => {
    if (!app?.feature?.forceFullscreenOnFirstTouch) return;
    document.body.addEventListener("click", requestFullScreen);
    return () => {
      document.body.removeEventListener("click", requestFullScreen);
    };
  }, [app?.feature?.forceFullscreenOnFirstTouch]);

  if (isAccountBlock) {
    return (
      <AccountBlocked className={classes.contentBlock} onBack={onBackLogin} />
    );
  }

  if (
    cognitoUserStatus === CognitoUserState.UNLOADED ||
    !isThemeLoaded ||
    !isI18NLoaded
  ) {
    return null;
  }

  if (!isOnline) {
    return <ErrorScreen type={PopupType.NoInternet} />;
  }

  return (
    <Suspense fallback={<OnmoLoading />}>
      {alert.isShowAlert && (
        <Alert message={alert.message} classify={alert.classify} />
      )}
      {maintenanceDetails.isEnabled ? (
        <ErrorScreen type={PopupType.Maintenance} />
      ) : signedIn ? (
        <RoutesLoggedWithProvider />
      ) : (
        <RoutesNotLogged />
      )}
    </Suspense>
  );
};
