import { includes, find, compact, reduce, get } from "lodash-es";
import { SubscriptionStatus } from "../../constants/user";
import { IWalletUpdated } from "../../types/shopWallet";
import { Time } from "../../models/time";
import { PushNotification } from "../pushNotification";
import { getVirtualWallet } from "../wallets/wallets";
import { SystemMaintenanceError } from "../../constants/apollo";
import { Currency } from "../../constants/shopWallet";
import {
  CoinType,
  CoinWallet,
  FreeCoins,
  PermissionGroup,
  TransactionActionType,
  UserInfo,
  UserV2,
} from "../../gql/graphql";
import { OnmoStorage } from "../onmoStorage";

export const isAdmin = (userInfo: UserInfo) => {
  return (
    includes(userInfo.permissionGroups, PermissionGroup.Editor) ||
    includes(userInfo.permissionGroups, PermissionGroup.Publisher)
  );
};

export const isQaRole = (userInfo: UserInfo | null) => {
  return includes(userInfo?.permissionGroups, PermissionGroup.Qa);
};

export const getWalletsFromFreeCoins = (user: UserV2, freeCoins: FreeCoins) => {
  switch (freeCoins?.status) {
    case "MAX_BALANCE_EXCEED":
      throw new Error("Maximum coin balance allowed to claim free coins");
    case "USER_IN_CASH_REGION":
      throw new Error("Get free coin cannot be used for cash region users");
    case "USE_IN_NEXT_AVAILABLE_TIME": {
      const nextTimeGetCoin = new Date(freeCoins?.nextAvailableTime);
      // SUBSTRACT ONE DAY FROM START NEXT TIME
      nextTimeGetCoin.setDate(nextTimeGetCoin.getDate() - 1);
      OnmoStorage.setTimeClaimCoin(user.id, nextTimeGetCoin.getTime());
      throw new Error(
        `You can get free coin on ${Time.formatDateGetCoin(
          freeCoins?.nextAvailableTime
        )}`
      );
    }
    case "FAILED":
      throw new Error("Failed to award the coins");
    case "SUCCESS": {
      const coinWallet = getVirtualWallet(user.wallets);
      if (coinWallet) {
        const updatedCoinWallet: CoinWallet[] = [
          {
            ...coinWallet,
            coinType: CoinType.Basic,
            balance: (coinWallet?.balance || 0) + 1000,
            __typename: "CoinWallet",
          },
        ];

        return updateWallets(user.wallets, updatedCoinWallet);
      }
    }
  }
};

export const isEnableNotification = (user?: UserV2) => {
  return !!user?.pushSubscription && user?.pushSubscription !== "null";
};

export const togglePushSubscription = async (user?: UserV2) => {
  if (isEnableNotification(user)) {
    return PushNotification.unsubscribe();
  } else {
    return PushNotification.subscribe();
  }
};

export const isSubscribedB2b = (userInfo: UserInfo) => {
  return userInfo.subscription?.status === SubscriptionStatus.subscribed;
};

export const isUnsubscribedB2b = (userInfo: UserInfo) => {
  return (
    userInfo.subscription?.status === SubscriptionStatus.unsubscribed ||
    userInfo.subscription === null
  );
};

export const isPendingB2b = (userInfo: UserInfo) => {
  return userInfo.subscription?.status === SubscriptionStatus.pending;
};

export const convertEventWalletToWallet = (
  eventWallet: IWalletUpdated
): CoinWallet | null => {
  if (eventWallet.currency === Currency.Xxx) {
    return {
      balance: Math.abs(eventWallet.balance),
      coinType: CoinType.Basic,
      userId: "",
      __typename: "CoinWallet",
    };
  }
  return null;
};

export const updateWallets = (
  wallets: CoinWallet[],
  newWallets: CoinWallet[]
): CoinWallet[] => {
  // Make sure newWallets/wallets are array type
  newWallets = newWallets || [];
  wallets = wallets || [];

  // Functor to find a specific wallet type
  const findCoinWallet = (w: CoinWallet) => w.coinType === CoinType.Basic;
  const findGemWallet = (w: CoinWallet) => w.coinType === CoinType.Gems;

  // Find coin wallets
  const newCoinWallet = newWallets.find(findCoinWallet);
  const coinWallet = wallets.find(findCoinWallet);

  // Find gem wallets
  const newGemWallet = newWallets.find(findGemWallet);
  const gemWallet = wallets.find(findGemWallet);

  // Functor to replace a wallet if the sequence number is more recent
  // than the current wallet
  const replaceWallet = (
    wallet?: CoinWallet,
    newWallet?: CoinWallet
  ): CoinWallet | null => {
    return newWallet || wallet || null;
  };

  // Remove null values if wallet does not exist
  const updatedWallets = compact([
    replaceWallet(coinWallet, newCoinWallet),
    replaceWallet(gemWallet, newGemWallet),
  ]) as CoinWallet[];

  return updatedWallets;
};

export const getFormatDateLanguage = (user: UserV2) => {
  if (user.language.includes("en")) {
    return "en-GB";
  }
  return user.language;
};

export const findTransactionCoinsReceived = (userInfo: UserInfo) => {
  return find(
    userInfo.unseenRewardTransactions,
    (u) => u.transactionType === TransactionActionType.SubscriptionCoinsReceived
  );
};

export const findTransactionBonusCoinsReceived = (userInfo: UserInfo) => {
  return find(
    userInfo.unseenRewardTransactions,
    (u) => u.transactionType === TransactionActionType.BonusCoinsReceived
  );
};

export const findLastSeenTransaction = (userInfo: UserInfo) => {
  return reduce(userInfo.unseenRewardTransactions, (a, b) => {
    return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
  });
};

export const getCoinsBalance = (user: UserV2): number => {
  const wallet = user.wallets.find(
    (item: CoinWallet) => item.coinType === CoinType.Basic
  );
  return wallet?.balance ?? 0;
};

// TODO: move out
export const isErrorMaintenance = (e: Error | unknown) => {
  const error = get(e, "networkError.result.errors[0].extensions.code");
  if (error === SystemMaintenanceError) {
    return true;
  }
  return false;
};

export const listNotifications = (friendNotification: boolean) => {
  if (friendNotification) {
    return ["friends"];
  } else {
    return [];
  }
};

export const isAccountDisable = (message: string) => {
  return message === "User is disabled." || message === "User is not enabled";
};

export const isShowMyRank = (xp: number) => {
  if (!xp) return false;
  return xp > 0 && xp < 11;
};
