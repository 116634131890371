import { useTranslation } from "react-i18next";
import { PopupType } from "../constants/popup";
import BackIconWebp from "../assets/header/webp/close-icon-grey.webp";
import NotEnoughCoin from "../assets/shop/webp/not-enough-coin.webp";
import SuccessIconWebp from "../assets/shop/webp/transaction-purchase.webp";
import PersonalizeIconWebp from "../assets/menu/webp/profile.webp";
import PauseIcon from "../assets/liveStream/webp/pauseIcon.webp";
import PhoneBanWebp from "../components/common/LivePlayerList/assets/phone-ban.webp";
import TreasureWebp from "../components/common/OnmoShop/assets/treasure@3x.webp";
import { UserProfile } from "../gql/graphql";

interface IContent {
  emoticon?: string;
  closeIcon?: string;
  title?: string;
  description?: string;
  textButtonSuccess?: string;
  textButtonClose?: string;
}

export class PopupFactory {
  static getContent = (
    type?: string,
    userProfile?: UserProfile | null
  ): IContent | null => {
    const { t } = useTranslation();

    switch (type) {
      case PopupType.LivestreamFull:
        return {
          emoticon: PhoneBanWebp,
          closeIcon: "",
          title: t("ls_Sorry, it's packed!"),
          description: t(
            "ls_Looks like this livestream is at capacity. Choose another stream or start a new one."
          ),
          textButtonSuccess: t("ok"),
          textButtonClose: t(""),
        };
      case PopupType.LivestreamExpired:
        return {
          emoticon: PhoneBanWebp,
          closeIcon: "",
          title: t("ls_Sorry, the show's over!"),
          description: t(
            "The livestream you were trying to view has ended. Choose another stream or start a new one."
          ),
          textButtonSuccess: t("ok"),
          textButtonClose: t(""),
        };
      case PopupType.LeaveGameSession:
        return {
          emoticon: PauseIcon,
          closeIcon: BackIconWebp,
          title: t("popup_Quit & lose entry fee?"),
          description:
            t("deleteFriendDialog_Are you sure?") +
            " " +
            t(
              "ls_Your entry fee will not be refunded and your score will not be saved."
            ),
          textButtonSuccess: t("common_Cancel"),
          textButtonClose: t("livestream_quit"),
        };
      case PopupType.Unfriend:
        return {
          emoticon: PersonalizeIconWebp,
          title: `${t("common_Unfriend")} ${userProfile?.user?.username}?`,
          description: t("common_Are you sure? You can add back at any time.", {
            username: userProfile?.user?.username,
          }),
          textButtonSuccess: t("ls_Go Back"),
          textButtonClose: t("common_Unfriend"),
        };
      case PopupType.CongratulationsShop:
        return {
          emoticon: NotEnoughCoin,
          description: t(
            "earnFeeCoins_You can use it to play any coins battles or tournaments"
          ),
          textButtonSuccess: t("common_Continue"),
        };
      case PopupType.RechargeNow:
        return {
          emoticon: NotEnoughCoin,
          title: t("common_You dont have enough coins for this"),
          description: t(
            "common_Go to the shop to top up or subscribe for more coins."
          ),
          textButtonSuccess: t("common_Go to Shop"),
          textButtonClose: t("ls_Go Back"),
        };
      case PopupType.PaymentSuccessful:
        return {
          emoticon: SuccessIconWebp,
          textButtonSuccess: t("common_Continue"),
          textButtonClose: t(""),
        };
      case PopupType.PaymentFail:
        return {
          emoticon: SuccessIconWebp,
          closeIcon: BackIconWebp,
          title: t("purchaseDialog_Payment fail"),
          description: t(""),
          textButtonSuccess: t("common_Try again"),
          textButtonClose: t(""),
        };
      case PopupType.Welcome:
        return {
          emoticon: TreasureWebp,
          closeIcon: "",
          title: t(""),
          description: t(""),
          textButtonSuccess: t("common_Close"),
          textButtonClose: t(""),
        };
      case PopupType.PersonalizeProfile:
        return {
          emoticon: PersonalizeIconWebp,
          title: t("profile_Do you want to personalize your profile?"),
          description: t(
            "profile_You can do this later in your profile page, we will assign you a random username and avatar in the meantime."
          ),
          textButtonSuccess: t("popupStreamAskViewProfile_Proceed"),
          textButtonClose: t("userCoins_No, thanks"),
        };
      default:
        return null;
    }
  };

  static getContentErrorScreen = (
    type: string,
    appName?: string,
    reason?: string
  ) => {
    const { t } = useTranslation();

    switch (type) {
      case PopupType.Maintenance:
        return {
          title:
            reason === "servers"
              ? t("Maintenance_We are having issues", {
                  defaultValue: "We are having issues",
                })
              : t("Maintenance!", { defaultValue: "Maintenance!" }),
          description:
            reason === "servers"
              ? t(
                  "Maintenance_We are having internal issues with our servers, please come back later.",
                  {
                    defaultValue:
                      "We are having internal issues with our servers, please come back later.",
                  }
                )
              : t(
                  "maintenance_We're doing some maintenance, please come back later.",
                  {
                    defaultValue:
                      "We're doing some maintenance, please come back later.",
                  }
                ),
          textButton: t("noInternet_Refresh", { defaultValue: "Refresh" }),
        };
      case PopupType.NoInternet:
        return {
          title: t("common_Whoops", { defaultValue: "Whoops" }),
          description: t(
            "noInternet_No internet connection! Make sure wifi or cellular data is turned on.",
            {
              defaultValue:
                "No internet connection! Make sure wifi or cellular data is turned on.",
            }
          ),
          textButton: t("noInternet_Refresh", {
            defaultValue: "Refresh",
          }),
        };
      case PopupType.UnsupportedDevice:
        return {
          title: t("common_Unsupported device"),
          description: t(
            "common_is designed to play on mobile phones. You may continue, but some features may not display correctly.",
            { appName: appName }
          ),
          textButton: t("ok"),
        };
      case PopupType.UnsupportedBrowser:
        return {
          title: t("common_Heads up! Unsupported browser"),
          description: t(
            "common_Some features may not work with your browser. For the best gaming experience please use Google Chrome or Safari."
          ),
          textButton: t("ok"),
        };
    }
  };
}
