import { TabActivities } from "../../constants/activities";
import { groupBy, map } from "lodash-es";
import { Time } from "../time";
import {
  Activity,
  ActivityCategory,
  ActivityType,
  BattleFinishActivity,
  BattleInviteActivity,
  BattleUserNotificationData,
  CoinBattleType,
  DefaultActivity,
  LeaderboardActivity,
  PlayMatch,
  PublicUser,
  TournamentPlayedActivity,
} from "../../gql/graphql";

export interface IActivityCategoryFilters {
  battleType?: CoinBattleType;
  battleTypes?: CoinBattleType[];
  activityType?: ActivityType;
}

export interface IActivityCategoryWithFilters {
  filters: IActivityCategoryFilters;
  name: ActivityCategory;
}

export type ActivityNotDefault = Exclude<Activity, DefaultActivity>;

// Solo, Battles & Tournament activities excluding leaderboard
export type BattleActivity = Exclude<
  ActivityNotDefault,
  LeaderboardActivity | TournamentPlayedActivity
>;

export const isBattleActivity = (activity?: Activity) => {
  const battleActivity = activity as BattleActivity | undefined;
  return (
    battleActivity?.battleType === CoinBattleType.Battle ||
    battleActivity?.battleType === CoinBattleType.HouseBattle
  );
};

export const isChallengeActivity = (activity?: Activity) => {
  const battleActivity = activity as BattleActivity | undefined;
  return battleActivity?.battleType === CoinBattleType.Solo;
};

export const isTournamentActivity = (activity?: Activity) => {
  const battleActivity = activity as BattleActivity | undefined;
  return battleActivity?.battleType === CoinBattleType.Tournament;
};

export const isLeaderboardActivity = (activity?: Activity) => {
  return activity?.__typename === "LeaderboardActivity";
};

export const getOpponent = (activity?: Activity) => {
  const battleActivity = activity as BattleInviteActivity | undefined;
  return battleActivity?.opponentUsers?.[0];
};
export const getActivityGameTitle = (activity?: Activity) => {
  const battleActivity = activity as BattleInviteActivity | undefined;
  return battleActivity?.gameTitle;
};

export const isUnranked = (activity?: Activity) => {
  const battleActivity = activity as BattleFinishActivity | undefined;
  return !battleActivity?.rank;
};

export const hasWinner = (activity?: Activity) => {
  const battleActivity = activity as BattleFinishActivity | undefined;
  return !!battleActivity?.winner;
};

export const groupActivitiesByStartedDate = (
  activities: Activity[]
): Record<string, Activity[]> | undefined => {
  if (!activities || !activities.length) return;
  const arenaActivities = map(activities, (activity) => {
    return {
      ...activity,
      displayDate: Time.formatDate(
        activity.createdAt,
        "yyyy-mm-dd",
        activity.activityType === ActivityType.BattleInviteSent ||
          activity.activityType === ActivityType.BattlePlayed
      ),
    };
  });

  const priorityActivities = arenaActivities
    .filter((activity) => activity.displayDate === "Recent")
    .concat(
      arenaActivities.filter((activity) => activity.displayDate !== "Recent")
    );

  return groupBy(priorityActivities, "displayDate");
};

export const subscriptionFriendBattleToActivity = (
  battleRequest: PlayMatch
): Activity => {
  const fullBattleInfo = battleRequest?.fullBattleInfo;
  const opponent = battleRequest.players.find(
    (player) => player.id === battleRequest?.fullBattleInfo?.hostUserId
  );

  return {
    __typename: "BattleInviteActivity",
    activityType: ActivityType.BattleInvited,
    battleId: fullBattleInfo.matchId,
    battleType: CoinBattleType.Battle,
    createdAt: new Date().toISOString(),
    data: JSON.stringify({
      userId: "",
      hostUserId: opponent?.id,
      momentId: fullBattleInfo.momentId,
      gameId: fullBattleInfo.gameId,
      battleId: fullBattleInfo.matchId,
      gameTitle: "",
      battleType: CoinBattleType.Battle,
    }),
    gameId: fullBattleInfo.gameId,
    gameTitle: "",
    momentId: fullBattleInfo.momentId,
    opponentUsers: [
      {
        __typename: "PublicUser",
        avatar: opponent?.avatar || "",
        banner: "",
        id: opponent?.id || "",
        username: opponent?.username || "",
        xp: opponent?.xp || 0,
        cheatSessions: 0,
      } as PublicUser,
    ],
  };
};
export const subscriptionNotificationToActivity = (
  battleRequest: BattleUserNotificationData
): Activity => {
  return {
    __typename: "BattleInviteActivity",
    activityType: ActivityType.BattleInvited,
    battleId: battleRequest.matchId,
    battleType: CoinBattleType.Battle,
    createdAt: new Date().toISOString(),
    data: JSON.stringify({
      userId: "",
      hostUserId: battleRequest.host.id,
      gameId: battleRequest.gameId,
      battleId: battleRequest.matchId,
      gameTitle: "",
      battleType: CoinBattleType.Battle,
    }),
    gameId: battleRequest.gameId,
    gameTitle: "",
    momentId: "",
    opponentUsers: [
      {
        __typename: "PublicUser",
        avatar: battleRequest.host?.avatar || "",
        id: battleRequest.host?.id || "",
        username: battleRequest.host?.username || "",
        xp: battleRequest.host?.xp || 0,
        followStatus: battleRequest.host.followStatus,
        lastCheckin: battleRequest.host.lastCheckin,
      } as PublicUser,
    ],
  };
};

export const categoryWithFilters = (
  type: string
): IActivityCategoryWithFilters | undefined => {
  const activityCategoryfilters: IActivityCategoryWithFilters = {
    name: ActivityCategory.Profile,
    filters: {},
  };

  switch (type) {
    case TabActivities.Battles:
      activityCategoryfilters.filters.battleTypes = [
        CoinBattleType.Battle,
        CoinBattleType.HouseBattle,
      ];
      return activityCategoryfilters;
    case TabActivities.Challenges:
      activityCategoryfilters.filters.battleTypes = [CoinBattleType.Solo];
      return activityCategoryfilters;
    case TabActivities.Tournaments:
      activityCategoryfilters.filters.battleTypes = [CoinBattleType.Tournament];
      return activityCategoryfilters;
    case TabActivities.Leaderboards:
      activityCategoryfilters.filters.activityType =
        ActivityType.LeaderboardEnded;
      return activityCategoryfilters;
  }
};
