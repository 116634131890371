export type GridPadding =
  | "none"
  | "tiny1"
  | "tiny2"
  | "tiny3"
  | "xxs"
  | "xs"
  | "s"
  | "m"
  | "l"
  | "xl"
  | "xxl"
  | "xxxl"
  | "h"
  | "xh"
  | "xxh";

export const GridPaddingMapping: { [key in GridPadding]: number } = {
  none: 0,
  tiny1: 1,
  tiny2: 2,
  tiny3: 2,
  xxs: 4,
  xs: 8,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  xxl: 28,
  xxxl: 32,
  h: 40,
  xh: 44,
  xxh: 46,
};

export const GridPaddingHeader = 70;
export const GridPaddingNavigation = 76;
