import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { Livestream } from "../gql/graphql";
import { listLivestreams } from "../graphql/resolvers/queries/liveStream";
import { AppThunk } from "../store";
import { LiveStreamFactory } from "../models/liveStreamFactory";

interface ILivePlayers {
  livePlayers: Livestream[];
}

const initialState = {
  livePlayers: [],
} as ILivePlayers;

export const resetLivePlayersState = createAction("livePlayers/resetState");

export const livePlayersSlice = createSlice({
  name: "livePlayers",
  initialState: initialState,
  reducers: {
    setLivePlayers: (
      state,
      action: PayloadAction<{ livePlayers: Livestream[] }>
    ) => {
      state.livePlayers = action.payload.livePlayers;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetLivePlayersState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
const { setLivePlayers } = livePlayersSlice.actions;

export const getLivePlayersAction = (): AppThunk => async (dispatch) => {
  const liveStream = await listLivestreams();
  const liveStreamOrdered = LiveStreamFactory.orderByTypeAndStartAt(
    liveStream.items
  );
  dispatch(setLivePlayers({ livePlayers: liveStreamOrdered }));
};

export default livePlayersSlice.reducer;
