export const TIME_SHOW_ENDSCREEN = 0.75; // TIME ANIMATION TO SHOW END SCREEN POPUP
export const MIN_WIDTH = 10;
export const MAX_WIDTH = 90;
export const LIST_POSITION = [40, 45, 55, 60, 70, 75, 85];
export const START_END_POSITION = [40, 80, 100, 120];
export const LANDSCAPE_RATIO = 50;
export const NB_SECOND_SHOW_LEVEL = 1;
export const NB_SECOND_RUN_CONFETTI = 1;
export const NB_SECOND_SHOW_NEXT_LEVEL = 1;
export const TIMEOUT_COUNTER = 500;
export const NB_GEMS_FLY = 20;
export const NB_SECOND_SHOW_PROGRESS = 1500;
export const LEFT_DIMENSION_GEM = 50;
export const TOP_DIMENSION_GEM = 30;
