export enum TransactionType {
  Debit = "debit",
  Credit = "credit",
}

export enum CoinType {
  BASIC = "basic",
  DEPOSIT = "deposit",
  BONUS = "bonus",
  WINNINGS = "winnings",
  GEMS = "gems",
}

export enum TransactionStatus {
  Pending = "pending",
  Completed = "completed",
  Cancelled = "cancelled",
  Expired = "expired",
  Played = "played",
}

export const LIMIT_LOAD_TRANSACTION = 50;
