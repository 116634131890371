export enum MomentType {
  Tutorial = "TUTORIAL",
  Casual = "CASUAL",
  Challenge = "CHALLENGE",
  Battle = "BATTLE",
  Zen = "ZEN",
  Time = "TIME",
}

export enum RankOrder {
  Desc = "DESC",
  Asc = "ASC",
}

export enum TypeMoment {
  Time = "Time",
  Score = "Score",
}

export enum MomentStatus {
  Draft = "DRAFT",
  Upcoming = "UPCOMING",
  Live = "LIVE",
  Disabled = "DISABLED",
}
